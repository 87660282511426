import React, { useEffect, useState } from "react"
import Backend from "../../utility/Backend"
import Config from "../../utility/Config"
import CustomModal from "../../components/CustomModal"
import { useLoginSession } from "../../stores/loginSession"
import { showErrorMessage } from "../../utility/Utilities"
import { FaTwitter } from "react-icons/fa"
import "./ShareVideo.css"
import CaptionOptions from "./CaptionOptions"

function ShareToTwitter({ shareVideo, onClose, openShareTable }) {
    const { token } = useLoginSession()
    const video = shareVideo.video

    const [twitterUser, setTwitterUser] = useState(null)
    const [loggingOutTwitter, setLoggingOutTwitter] = useState(false)
    const [isLoadingCredentials, setIsLoadingCredentials] = useState(true)
    const [error, setError] = useState(null)
    const [popupClosed, setPopupClosed] = useState(false)
    const [caption, setCaption] = useState("") 
    const [isOptionsVisible, setIsOptionsVisible] = useState(false) 
    const [generatedCaption, setGeneratedCaption] = useState("")

    const openTwitterLogin = () => {
        const leaguePath = window.location.pathname.split("/")[1]
        const twitterLoginUrl = `${Config.api}${leaguePath}/socials/credentials/twitter/authorize?access_token=${token}`
        const { outerWidth: screenWidth, outerHeight: screenHeight, screenX, screenY } = window
        const [windowWidth, windowHeight] = [750, 750]
        const left = screenX + (screenWidth - windowWidth) / 2
        const top = screenY + (screenHeight - windowHeight) / 2

        const loginPopup = window.open(twitterLoginUrl, "_blank", `width=${windowWidth}, height=${windowHeight}, left=${left}, top=${top}`)

        const popupChecker = setInterval(() => {
            if (loginPopup.closed) {
                clearInterval(popupChecker)
                setPopupClosed(true)
                setIsLoadingCredentials(true)
            }
        }, 500)
    }

    const fetchTwitterCredential = async () => {
        try {
            const { data: twitterCredentials } = await Backend.get("/socials/credentials/twitter")
            if (twitterCredentials && twitterCredentials.length > 0) {
                const credential = twitterCredentials[0]
                setTwitterUser(credential)
            } else {
                setTwitterUser(null)
            }
        } catch (error) {
            console.error("Failed to fetch Twitter credentials", error)
            setError("Failed to fetch Twitter credentials. Please try again.")
        } finally {
            setIsLoadingCredentials(false)
        }
    }

    useEffect(() => {
        if (popupClosed) {
            fetchTwitterCredential()
        }
    }, [popupClosed])

    const resetTwitterShare = () => {
        setTwitterUser(null)
        setCaption("")
        setLoggingOutTwitter(false)
        setError(null)
        setIsLoadingCredentials(false)
        setPopupClosed(false)
    }

    const onShareToTwitter = async () => {
        const shareToTwitterBody = {
            media_type: "VIDEO",
            video_url: video.download_link,
            caption: generatedCaption || caption,
            twitter_user_id: twitterUser.twitter_user_id
        }

        try {
            const response = await Backend.post(`/socials/share/twitter/${twitterUser.twitter_user_id}`, JSON.stringify(shareToTwitterBody))
            if (response.error) {
                setError(response.error)
            } else {
                onClose()
                openShareTable()
            }
        } catch (error) {
            setError(error.message || "An unknown error occurred.")
        }
    }

    const onLogoutTwitter = async () => {
        try {
            await Backend.delete(`/socials/credentials/twitter/${twitterUser?.twitter_user_id}`)
            resetTwitterShare()
        } catch (error) {
            setError(error)
        }
    }

    const twitterUserDetails = (
        <div className="user-logged-in">
            <div className="logged-in-title">Logged in as {twitterUser?.twitter_screen_name}</div>
            {twitterUser ? (
                <div className="share-user-profile">
                    <div className="user-profile-and-name">
                        <img src={twitterUser.twitter_profile_image_url} alt="profile img" />
                        <div>{twitterUser.twitter_screen_name}</div>
                        <button onClick={() => setLoggingOutTwitter(true)} className="small-btn red-btn">Logout</button>
                    </div>
                    {loggingOutTwitter && (
                        <div className="confirm-logout show">
                            <div className="create-delete-credentials-title">Confirm logout?</div>
                            <div className="delete-credentials-btn">
                                <button onClick={onLogoutTwitter} className="red-btn small-btn">Logout</button>
                                <button onClick={() => setLoggingOutTwitter(false)} className="small-btn">Cancel</button>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div className="no-credentials-found">
                    <button onClick={openTwitterLogin} className="login-with-twitter-btn">
                        <FaTwitter />
                        <span style={{ marginLeft: "5px" }}>Login with Twitter</span>
                    </button>
                </div>
            )}
            {error && showErrorMessage("Please login to share", !twitterUser)}
            {error && <div className="share-login-error">{error}</div>}
        </div>
    )

    const shareToTwitterForm = (
        <div className="share-video-form">
            <div className="input-container">
                <label className="input-title">
                    Tweet
                    <small className="optional-text">Optional</small>
                </label>
                <textarea
                    value={caption}
                    onChange={(e) => setCaption(e.target.value)}
                    className="share-video-title-input"
                    maxLength="280"
                    placeholder="Write your tweet"
                    rows="10"
                    style={{ resize: "vertical" }}
                />
                <span className="char-count-twitter">{280 - caption.length}</span>
                <button 
                    onClick={() => setIsOptionsVisible(!isOptionsVisible)} 
                    className="generate-description-btn">
                    {isOptionsVisible ? "Hide Options" : "Generate Caption"}
                </button>
            </div>
            {isOptionsVisible && (
                <CaptionOptions 
                    caption={caption}
                    video={video}
                    onCaptionGenerated={(generatedCaption) => setGeneratedCaption(generatedCaption)}
                    platform={"Twitter"}
                />
            )}
        </div>
    )

    return (
        <CustomModal isOpen={!!shareVideo} onRequestClose={onClose} className="share-modal">
            <div className="share-video-modal">
                <div className="share-video-title">Share video to Twitter</div>
                <div className="share-video-and-credentials">
                    <div className="share-video-review">
                        <video src={video.download_link} controls></video>
                    </div>
                    <div className="credentials-and-form">
                        {twitterUserDetails}
                        {shareToTwitterForm}
                        <div className="confirm-cancel-btn-cont right">
                            <button onClick={onShareToTwitter} disabled={!!error} className="share-to-social-media-btn green-btn">
                                Share to Twitter
                            </button>
                            <button onClick={onClose}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </CustomModal>
    )
}

export default ShareToTwitter
