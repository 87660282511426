import React, { useState, useEffect } from "react";
import {useLoginSession} from "../../stores/loginSession";
import { useGetUsersGroups } from "../../utility/UserGroups";
import ConfirmModal from "../../components/ConfirmModal";
import Page from "../../components/Page";
import {Helmet} from "react-helmet-async";
import Config from "../../utility/Config";
import Backend from "../../utility/Backend";
import CustomModal from "../../components/CustomModal";
import { showErrorMessage } from "../../utility/Utilities";
import { useFeedbackMessage } from "../../stores/FeedbackMessage";
import "./Profile.css";
import {AiOutlineMail} from "react-icons/ai";
import {GoListUnordered} from "react-icons/go";
import {BiGroup, BiLogOut} from "react-icons/bi";
import {FaUserAlt} from "react-icons/fa";
import {FiUserCheck} from "react-icons/fi";

export default function Profile () {
    const {user, token, username, logOut} = useLoginSession();
    const usersUserGroups = useGetUsersGroups();
    const {showFeedback} = useFeedbackMessage();

    const [logOutConfirmation, setLogOutConfirmation] = useState(false);
    const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false)

    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [repeatNewPassword, setRepeatNewPassword] = useState("")
    const [wrongOldPassword, setWrongOldPassword] = useState(false)

    useEffect(() => {
        setWrongOldPassword(false)
    }, [oldPassword])

    if (!usersUserGroups) return null

    const usersUserGroupsList = usersUserGroups.map(user => user.name)

    const openLogOutConfirmation = () => {
        setLogOutConfirmation(true);
    }

    const closeLogOutConfirmation = () => {
        setLogOutConfirmation(false);
    }

    const resetChangePassword = () => {
        setOldPassword("")
        setNewPassword("")
        setRepeatNewPassword("")
        setWrongOldPassword(false)
    }

    const closeChangePasswordModal = () => {
        setIsChangePasswordOpen(false)
        resetChangePassword()
    }

    const changePassword = () => {

        const query = {access_token: token};
        const password = {
            old_password : oldPassword,
            password : newPassword 
        }

        Backend.put("/user/password", JSON.stringify(password), query)
            .then(({error}) => {
                if (error) {
                    console.error("Failed to change password", error)
                    if (error === "Wrong password") {
                        setWrongOldPassword(true)
                        showFeedback("warning", "Failed to change password. Wrong password.")
                        return
                    }
                    showFeedback("warning", "Failed to change password. Please try again and fill the input correctly.");
                } else {
                    showFeedback("success", "Password changed successfully!");
                    closeChangePasswordModal()
                }
            });
        return false;
    }

    const emptyInput = oldPassword === "" || newPassword === "" || repeatNewPassword === ""
    const newPasswordShort = newPassword !== "" && newPassword.length < 10
    const repeatNewPasswordNotMatch = repeatNewPassword !== "" && (!newPassword.includes(repeatNewPassword) || newPassword.length !== repeatNewPassword.length)
    const disableChangePassword = newPasswordShort || repeatNewPasswordNotMatch || wrongOldPassword || emptyInput

    const profileAttributes = (
        <div className="pa-cont">
            <div className="pa-single">
                <AiOutlineMail className="pa-icon"/>
                <div className="pa-info">
                    <div className="pa-info-title">Email</div>
                    <div>{user.email || user.fotbollkonto_unique_name}</div>
                </div>
            </div>
            <div className="pa-single">
                <FiUserCheck className="pa-icon"/>
                <div className="pa-info">
                    <div className="pa-info-title">User since</div>
                    <div>{user.member_since}</div>
                </div>
            </div>
            <div className="pa-single">
                <GoListUnordered className="pa-icon"/>
                <div className="pa-info">
                    <div className="pa-info-title">Published</div>
                    <div>{user.num_playlists} videos</div>
                </div>
            </div>
            <div className="pa-single">
                <BiGroup className="pa-icon"/>
                <div className="pa-info">
                    <div className="pa-info-title">User groups ({usersUserGroupsList.length})</div>
                    <div className="pa-info-groups">
                        {usersUserGroupsList.map((group, idx) => {
                            const coma = (usersUserGroupsList.length-1 !== idx && usersUserGroupsList.length !==0) && ","
                            return (
                                <div key={group}>{group}{coma}</div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    );

    const fullProfile = (
        <div className="profile-cont">
            <div className="profile-info-cont">
                <div className="profile-pic-and-name">
                    <div className="profile-pic-cont">
                        <FaUserAlt className="user-icon"/>
                    </div>
                    <div className="profile-details-cont">
                        <div className="user-name">{username}</div>
                        <button type="button" onClick={openLogOutConfirmation} className="red-btn">
                            Log out
                        </button>
                        <button type="button" onClick={() => setIsChangePasswordOpen(true)}>
                            Change password
                        </button>
                    </div>
                </div>
                {profileAttributes}               
            </div>
            <div className="profile-build-version-text">
                Version: {Config.buildVersion}
            </div>
        </div>
    )
    
    // TODO new component
    const changePasswordModal = (
        <CustomModal isOpen onRequestClose={() => setIsChangePasswordOpen(false)} className="small">
            <div className="change-password-cont">
                <form action="" method="" className="change-password-form">
                    <div className="change-password-title">Change password</div>
                    <div className="input-container">
                        <label htmlFor="current-password" className="input-title">Old password</label>
                        <input
                            id="current-password"
                            type="password"
                            onChange={(e) => setOldPassword(e.target.value)}
                            value={oldPassword}
                            autoComplete="current-password"
                            className="upload-title-input"/>
                    </div>
                    <div className="change-password-warning">
                        {showErrorMessage("Wrong password", wrongOldPassword)}
                    </div>
                    <div className="input-container">
                        <label htmlFor="new-password" className="input-title">New password</label>
                        <input
                            id="new-password"
                            type="password"
                            onChange={(e) => setNewPassword(e.target.value)}
                            value={newPassword}
                            autoComplete="new-password"
                            placeholder="Minimum 10 characters"
                            className="upload-title-input"/>
                    </div>
                    <div className="change-password-warning">
                        {showErrorMessage("Minimum 10 characters", newPasswordShort)}
                    </div>
                    <div className="input-container">
                        <label htmlFor="new-password" className="input-title">Repeat new password</label>
                        <input
                            id="new-password"
                            type="password"
                            onChange={(e) => setRepeatNewPassword(e.target.value)}
                            value={repeatNewPassword}
                            autoComplete="new-password"
                            placeholder="Same as new password"
                            className="upload-title-input"/>
                    </div>
                    <div className="change-password-warning">
                        {showErrorMessage("Repeated password does not match the new password", repeatNewPasswordNotMatch)}
                    </div>                
                </form>
                <div className="confirm-cancel-btn-cont full padding-bottom">
                    <button type="button" disabled={disableChangePassword} onClick={changePassword} className="green-btn">Submit</button>
                    <button type="button" onClick={closeChangePasswordModal}>Cancel</button>
                </div>
            </div>
        </CustomModal>
    )

    const actionConfirmationModal = (
        <ConfirmModal 
            isOpen
            onClose={closeLogOutConfirmation} 
            onConfirm={logOut} 
            confirmText = "Log out"
            >
            <div className="confirm-icon-message">
                <div className="confirm-icon"><BiLogOut/></div>
                <div className="confirm-title">Do you really want to log out?</div>
            </div>
        </ConfirmModal>
    );
    
    return (
        <Page centered title="My profile">
            <Helmet>
                <title>Profile</title>
            </Helmet>
            {fullProfile}
            {logOutConfirmation && actionConfirmationModal}
            {isChangePasswordOpen && changePasswordModal}
        </Page>
    );
}