import React, { useState, useEffect, useCallback, useRef } from "react"
import { useVideoPlayer } from "../../components/VideoPlayer/VideoPlayerProvider"
import { useBackend } from "../../utility/Backend"
import Backend from "../../utility/Backend"
import LoadingScreen from "../../components/LoadingScreen"
import ConfirmModal from "../../components/ConfirmModal"
import { useFeedbackMessage } from "../../stores/FeedbackMessage"
import { IoMdArrowDropdown } from "react-icons/io"
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa"
import throttle from "lodash/throttle"
import debounce from "lodash/debounce"
import "./Subtitles.css"
import Config from "../../utility/Config"

function Subtitles ({ playlist, onDone }) {
    const { getCurrentTime, playerRef } = useVideoPlayer()
    const event = playlist.events[0]
    const assetId = playlist.master_videoasset?.id || event?.video_asset_id
    const fromTimestamp = event.from_timestamp
    const toTimestamp = event.to_timestamp
    const availableLanguages = ["nb", "en", "sv"]
    const languageNames = {
        nb: "Norwegian",
        sv: "Swedish",
        en: "English",
    }
    const [selectedLanguage, setSelectedLanguage] = useState(playlist.language || "sv")
    const { data, error, mutate } = useBackend(`/subtitles/${assetId}/${selectedLanguage}/${fromTimestamp}_${toTimestamp}.json`)
    const [subtitlesData, setSubtitlesData] = useState([])
    const [localChanges, setLocalChanges] = useState({})
    const [editIndex, setEditIndex] = useState(-1)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isPublished, setIsPublished] = useState({})
    const [checkingPublishStatus, setCheckingPublishStatus] = useState(false)
    const subtitlesTableRef = useRef(null)
    const lastScrollTimeRef = useRef(0)
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
    const [selectedLang, setSelectedLang] = useState(null)
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const { showFeedback } = useFeedbackMessage()
    const fileInputRef = useRef(null)
    const [vttUrl, setVttUrl] = useState(null)

    useEffect(() => {
        if (data) {
            const sortedData = data.sort((a, b) => a.from_ts - b.from_ts)
            const mergedData = sortedData.map((sub) =>
                localChanges[sub.id] ? { ...sub, txt: localChanges[sub.id] } : sub
            )
            setSubtitlesData(mergedData)
        }
    }, [data, localChanges])

    const removeAllTextTracks = () => {
        if (playerRef.current) {
            const textTracks = playerRef.current.remoteTextTracks()
            for (let i = textTracks.length - 1; i >= 0; i--) {
                playerRef.current.removeRemoteTextTrack(textTracks[i])
            }
        }
    }

    const fetchVttFile = useCallback(async () => {
        try {
            const response = await Backend.get(`/subtitles/${assetId}/${selectedLanguage}/${fromTimestamp}_${toTimestamp}.vtt`)
            const vttText = await response.data
            const blob = new Blob([vttText], { type: "text/vtt" })
            const url = URL.createObjectURL(blob)
            setVttUrl(url)

            removeAllTextTracks()

            if (playerRef.current) {
                const trackElement = playerRef.current.addRemoteTextTrack(
                    {
                        kind: "subtitles",
                        src: url,
                        srclang: selectedLanguage,
                        label: languageNames[selectedLanguage],
                        default: true,
                    },
                    false
                )
                trackElement.track.mode = "showing"
            }
        } catch (error) {
            console.error("Error fetching VTT file:", error)
        }
    }, [assetId, selectedLanguage, fromTimestamp, toTimestamp, playerRef])

    useEffect(() => {
        fetchVttFile()
    }, [fetchVttFile])

    useEffect(() => {
        const handleScroll = throttle(() => {
            scrollToCurrentSubtitle()
        }, 1000)

        const interval = setInterval(handleScroll, 1000)
        return () => {
            clearInterval(interval)
            handleScroll.cancel()
        }
    }, [subtitlesData, getCurrentTime])

    useEffect(() => {
        checkPublishStatus()
    }, [])

    const checkPublishStatus = useCallback(async () => {
        setCheckingPublishStatus(true)
        try {
            const { data } = await Backend.get(`/asset/${assetId}/subtitles/publish`)
            setIsPublished(data.published || {})
        } catch (error) {
            console.error("Failed to fetch publication status:", error)
            setIsPublished({})
        } finally {
            setCheckingPublishStatus(false)
        }
    }, [assetId])

    const togglePublishStatus = (lang) => {
        setSelectedLang(lang)
        setIsConfirmModalOpen(true)
    }

    const handleConfirm = async () => {
        setIsConfirmModalOpen(false)
        setIsSubmitting(true)
        try {
            const updatedPublishedStatus = { ...isPublished, [selectedLang]: !isPublished[selectedLang] }
            await Backend.put(`/asset/${assetId}/subtitles/publish`, JSON.stringify({ published: updatedPublishedStatus }))
            await checkPublishStatus()
        } catch (error) {
            console.error("Error toggling publish status:", error)
            await checkPublishStatus()
        } finally {
            setIsSubmitting(false)
        }
    }

    const handleClose = () => setIsConfirmModalOpen(false)

    const submitSubtitle = async (subtitle) => {
        setIsSubmitting(true)
        const payload = {
            language: selectedLanguage,
            from_ts: subtitle.from_ts,
            to_ts: subtitle.to_ts,
            txt: subtitle.txt,
            props: subtitle.props || {},
            flags: subtitle.flags,
        }
        try {
            await Backend.put(`/subtitle/${subtitle.id}`, JSON.stringify(payload))
            showFeedback("success", "Subtitle submitted successfully")
            setLocalChanges((prev) => {
                const newChanges = { ...prev }
                delete newChanges[subtitle.id]
                return newChanges
            })
            mutate()
            await fetchVttFile()
        } catch (error) {
            console.error(`Failed to update subtitle with ID ${subtitle.id}:`, error)
            showFeedback("warning", "Submission failed")
        } finally {
            setIsSubmitting(false)
        }
    }

    const safeSeekTo = useCallback(
        (time) => {
            if (playerRef.current && typeof playerRef.current.currentTime === "function") {
                playerRef.current.currentTime(time)
            } else {
                console.error("playerRef.current.currentTime is not a function")
            }
        },
        [playerRef]
    )

    const scrollToCurrentSubtitle = useCallback(
        debounce(() => {
            const currentTime = getCurrentTime()
            const currentSubtitleIndex = subtitlesData.findIndex(
                (subtitle) => currentTime >= subtitle.from_ts && currentTime <= subtitle.to_ts
            )

            if (currentSubtitleIndex !== -1 && subtitlesTableRef.current) {
                const now = Date.now()
                if (now - lastScrollTimeRef.current > 1000) {
                    const row = subtitlesTableRef.current.querySelector(`tr[data-index="${currentSubtitleIndex}"]`)
                    if (row) {
                        row.scrollIntoView({ behavior: "smooth", block: "center" })
                        lastScrollTimeRef.current = now
                    }
                }
            }
        }, 200),
        [subtitlesData, getCurrentTime]
    )

    const handleFileChange = async (event) => {
        const file = event.target.files[0]
        if (file) {
            const formData = new FormData()
            formData.append("vtt", file)
            formData.append("params", JSON.stringify({ language: selectedLanguage }))

            try {
                const response = await Backend.post(`/asset/${assetId}/subtitles/import/vtt`, formData)

                if (response.response.ok && response.data === "") {
                    showFeedback("success", "VTT file uploaded successfully")
                    await mutate()
                    await fetchVttFile()
                } else {
                    console.error("Failed to upload VTT file:", response.error || "Unknown error occurred despite successful status")
                    showFeedback("warning", "Failed to upload VTT file")
                }
            } catch (error) {
                console.error("Exception when uploading VTT file:", error)
                showFeedback("warning", "Exception when uploading VTT file.")
            }
        }
    }

    const triggerAutomaticSubtitleGeneration = async () => {
        const payload = {
            league: Config.activeLeague.path,
            method: "whisper",
            videoasset_id: assetId,
            language: selectedLanguage,
            audio_track: 1,
            from_ts: fromTimestamp,
            to_ts: toTimestamp,
        }

        try {
            const response = await Backend.post(
                `/subtitles/trigger_subtitler`,
                JSON.stringify(payload),
                {
                    headers: {
                        "Content-Type": "application/json",
                        "X-League-Name": Config.activeLeague.path,
                    }
                }
            )

            if (response.response.ok) {
                showFeedback("success", "Automatic subtitle generation triggered successfully.")
                await fetchVttFile()
            } else {
                console.error(
                    "Failed to trigger automatic subtitle generation:",
                    response.error || "Unknown error occurred despite successful status"
                )
                showFeedback("warning", "Failed to trigger automatic subtitle generation.")
            }
        } catch (error) {
            console.error("Exception when triggering automatic subtitle generation:", error)
            showFeedback("warning", "Exception when triggering automatic subtitle generation.")
        }
    }

    if (!data) return <LoadingScreen />
    if (error) return <div>Error when fetching subtitles</div>

    const handleRowClick = (timestamp) => safeSeekTo(timestamp)

    const toggleEdit = (index) => setEditIndex(editIndex === index ? -1 : index)

    const handleEditChange = (event, index) => {
        const newChanges = { ...localChanges, [subtitlesData[index].id]: event.target.value }
        setLocalChanges(newChanges)
        const updatedSubtitles = subtitlesData.map((subtitle, i) =>
            i === index ? { ...subtitle, txt: event.target.value } : subtitle
        )
        setSubtitlesData(updatedSubtitles)
    }

    const handleBlur = (index) => {
        submitSubtitle(subtitlesData[index])
        setEditIndex(-1)
    }

    const handleLanguageChange = (lang) => {
        setSelectedLanguage(lang)
        setIsDropdownOpen(false)
    }

    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen)

    return (
        <div className="subtitles-container">
            <div className={`filter-dropdown ${isDropdownOpen ? "open" : ""}`}>
                <div className="filter-title">Select the Subtitles</div>
                <div className="filter-selected" onClick={toggleDropdown}>
                    {languageNames[selectedLanguage] || selectedLanguage.toUpperCase()}
                    <span className="dropdown-arrow"><IoMdArrowDropdown /></span>
                </div>
                <ul className="filter-dropdown-list" style={{ zIndex: 11 }}>
                    {availableLanguages.map((lang) => (
                        <li
                            key={lang}
                            tabIndex="0"
                            className={`dropdown-item ${selectedLanguage === lang ? "active" : ""}`}
                            onClick={() => handleLanguageChange(lang)}
                        >
                            {languageNames[lang] || lang.toUpperCase()}
                        </li>
                    ))}
                </ul>
            </div>
            <div className="subtitles-table-container">
                <table className="subtitles-table" ref={subtitlesTableRef}>
                    <thead>
                        <tr>
                            <th>From (s)</th>
                            <th>To (s)</th>
                            <th>Text</th>
                            <th>Edit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {subtitlesData.map((subtitle, index) => (
                            <tr
                                key={index}
                                data-index={index}
                                onClick={() => handleRowClick(subtitle.from_ts)}
                                className={
                                    getCurrentTime() >= subtitle.from_ts && getCurrentTime() <= subtitle.to_ts
                                        ? "current-subtitle"
                                        : getCurrentTime() > subtitle.to_ts
                                            ? "past-subtitle"
                                            : "future-subtitle"
                                }
                            >
                                <td>{subtitle.from_ts}</td>
                                <td>{subtitle.to_ts}</td>
                                <td>
                                    {editIndex === index ? (
                                        <input
                                            type="text"
                                            value={subtitle.txt}
                                            onChange={(e) => handleEditChange(e, index)}
                                            onBlur={() => handleBlur(index)}
                                            autoFocus
                                        />
                                    ) : (
                                        subtitle.txt
                                    )}
                                </td>
                                <td>
                                    <button
                                        className="edit-button"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            toggleEdit(index)
                                        }}
                                    >
                                        Edit
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {subtitlesData.length === 0 && <div className="no-subtitles">No subtitles</div>}
            </div>
            <div className="data-info-cont">
                <div className="data-info-head-title">Subtitle Information</div>
                <div className="data-info">
                    <div className="data-info-title">Publishing Status of {languageNames[selectedLanguage] || selectedLanguage.toUpperCase()}: {isPublished[selectedLanguage] ? "Published" : "Not Published"}</div>
                    <div className="data-info-value">
                        {checkingPublishStatus ? (
                            <div className="loading">Checking publication status...</div>
                        ) : (
                            <div key={selectedLanguage}>
                                <button
                                    className={`button-publish ${isPublished[selectedLanguage] ? "red-btn" : "green-btn"}`}
                                    onClick={() => togglePublishStatus(selectedLanguage)}
                                    disabled={isSubmitting}
                                >
                                    {isPublished[selectedLanguage] ? "Unpublish" : "Publish"}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                <div className="data-info">
                    <div className="data-info-title">Uploading Subtitle</div>
                    <div className="data-info-value">
                        <div className="import-vtt-container">
                            <input
                                type="file"
                                accept=".vtt"
                                onChange={handleFileChange}
                                style={{ display: "none" }}
                                ref={fileInputRef}
                                id="vtt-file-input"
                            />
                            <button onClick={() => fileInputRef.current.click()}>Import VTT</button>
                        </div>
                    </div>
                </div>
                <div className="data-info">
                    <div className="data-info-title">Automatic Subtitle Generation</div>
                    <div className="data-info-value">
                        <button onClick={triggerAutomaticSubtitleGeneration}>Generate Subtitle</button>
                    </div>
                </div>
            </div>

            <ConfirmModal
                isOpen={isConfirmModalOpen}
                onClose={handleClose}
                onConfirm={handleConfirm}
                cancelText="Cancel"
                confirmText={isPublished[selectedLang] ? "Unpublish" : "Publish"}
            >
                <div className="confirm-icon-message">
                    <div className="confirm-icon">
                        {isPublished[selectedLang] ? <FaTimesCircle /> : <FaCheckCircle />}
                    </div>
                    <div className="confirm-title">
                        {`Are you sure you want to ${isPublished[selectedLang] ? "unpublish" : "publish"} the subtitles?`}
                    </div>
                </div>
            </ConfirmModal>
        </div>
    )
}

export default Subtitles
