import React, {useEffect, useState} from "react"
import { useUpdateSearchParams } from "./Utilities";
import classNames from "classnames";
import { capitalizeFirstLetter } from "./Utilities";
import { VscCalendar } from "react-icons/vsc";
import DatePicker from "react-datepicker";

// TODO handle this hook when in a file uses two hooks, 
// to prevent overwriting each other
export function usePeriodFilter (nonUrlParams=false, defaultDate=null) {
    
    const [customStartDate, setCustomStartDate] = useState(null)
    const [customEndDate, setCustomEndDate] = useState(null)
    const [openPeriodFilter, setOpenPeriodFilter] = useState(false)
    const [nonParamFrom, setNonParamFrom] = useState(null)
    const [nonParamTo, setNonParamTo] = useState(null)
    const [allTime, setAllTime] = useState(false)

    const [searchParams, updateSearchParams,] = useUpdateSearchParams()
    
    const periodFrom = nonUrlParams ? nonParamFrom : searchParams.get("from")
    const periodTo = nonUrlParams ? nonParamTo : searchParams.get("to")
    const from = allTime ? null : new Date(new Date(periodFrom).setHours(0,0,0,0)).getTime()
    const to = allTime ? null : new Date(new Date(periodTo).setHours(23,59,0,0)).getTime()

    const date = new Date()
    let thisYear = date.getUTCFullYear()
    let thisMonth = date.getUTCMonth()
    let today = date.getUTCDate()
    const dateToIsoString = (date) => date.toISOString().split("T")[0]

    // date
    const todayDate = dateToIsoString(date)
    // last 7 days
    const lastSevenDaysStartDate = dateToIsoString(new Date(date.setUTCFullYear(thisYear, thisMonth, today-6)))
    const lastSevenDaysEndDate = todayDate
    // this month
    const thisMonthStartDate = dateToIsoString(new Date(date.setUTCFullYear(thisYear, thisMonth, 1)))
    const thisMonthEndDate = dateToIsoString(new Date(date.setUTCFullYear(thisYear, thisMonth+1, 0)))
    // last month
    const lastMonthStartDate = dateToIsoString(new Date(date.setUTCFullYear(thisYear, thisMonth-1, 1)))
    const lastMonthEndDate = dateToIsoString(new Date(date.setUTCFullYear(thisYear, thisMonth, 0)))
    // this year
    const thisYearStartDate = dateToIsoString(new Date(date.setUTCFullYear(thisYear, 0, 1)))
    const thisYearEndDate = dateToIsoString(new Date(date.setUTCFullYear(thisYear, 12, 0)))
    // last year
    const lastYearStartDate = dateToIsoString(new Date(date.setUTCFullYear(thisYear-1, 0, 1)))
    const lastYearEndDate = dateToIsoString(new Date(date.setUTCFullYear(thisYear-1, 12, 0)))
    
    useEffect(() => {
        if (nonUrlParams) return
        if (!periodFrom || !periodTo) {
            updateSearchParams({"from": lastSevenDaysStartDate})
            updateSearchParams({"to": lastSevenDaysEndDate})
        }
    }, [periodFrom, periodTo, nonUrlParams])

    useEffect(() => {
        if (nonUrlParams) {
            if (defaultDate) {
                onChangePeriod(defaultDate)
            } else {
                setNonParamFrom(lastYearStartDate)
                setNonParamTo(lastYearEndDate)
            }
        }
    }, [])

    useEffect(() => {
        if (defaultDate === "All time") setAllTime(true)
    }, [defaultDate])

    // for now this one is specifically for free text search
    const onSetAllTime = () => {
        setAllTime(true)
        setOpenPeriodFilter(false)
        setCustomStartDate(null)
        setCustomEndDate(null)
    }
   
    const displayTimePeriod = () => {
        if (allTime) return "All time"
        if (!periodFrom || !periodTo) return
        if (periodFrom === todayDate && periodTo === todayDate) return "Today"
        if (periodFrom === lastSevenDaysStartDate && periodTo === lastSevenDaysEndDate) return "Last 7 days"
        if (periodFrom === thisMonthStartDate && periodTo === thisMonthEndDate) return "This month"
        if (periodFrom === lastMonthStartDate && periodTo === lastMonthEndDate) return "Last month"
        if (periodFrom === thisYearStartDate && periodTo === thisYearEndDate) return "This year"
        if (periodFrom === lastYearStartDate && periodTo === lastYearEndDate) return "Last year"
        else return `${periodFrom} to ${periodTo}`
    }
    
    const onChangePeriod = (period) => {

        let startDate = todayDate
        let endDate = todayDate

        switch (period) {
            case "Last 7 days": 
                startDate = lastSevenDaysStartDate
                endDate = lastSevenDaysEndDate
                break
            case "This month": 
                startDate = thisMonthStartDate
                endDate = thisMonthEndDate
                break
            case "Last month": 
                startDate = lastMonthStartDate
                endDate = lastMonthEndDate
                break
            case "This year": 
                startDate = thisYearStartDate
                endDate = thisYearEndDate
                break
            case "Last year": 
                startDate = lastYearStartDate
                endDate = lastYearEndDate
                break
            default:
                break
        }
        if (nonUrlParams) {
            setNonParamFrom(startDate)
            setNonParamTo(endDate)
        } else {
            updateSearchParams({"from": startDate})
            updateSearchParams({"to": endDate})
        }
        if (allTime) setAllTime(false)
        setOpenPeriodFilter(false)
        setCustomStartDate(null)
        setCustomEndDate(null)
    }

    const padToTwoDigits = (number) => {
        return number.toString().length === 1 ? `0${number}` : number
    }

    const onChangeCustomDate = (dates) => {
        const [start, end] = dates
        
        setCustomStartDate(start)
        setCustomEndDate(end)

        if (start && end) {

            const startYear = start.getFullYear()
            const startMonth = padToTwoDigits(start.getMonth()+1) 
            const startDay = padToTwoDigits(start.getDate())
            const endYear = end.getFullYear()
            const endMonth = padToTwoDigits(end.getMonth()+1)
            const endDay = padToTwoDigits(end.getDate())

            const startDate = `${startYear}-${startMonth}-${startDay}`
            const endDate = `${endYear}-${endMonth}-${endDay}`
            
            if (nonUrlParams) {
                setNonParamFrom(startDate)
                setNonParamTo(endDate)
            } else {
                updateSearchParams({"from": startDate})
                updateSearchParams({"to": endDate})
            }
            if (allTime) setAllTime(false)
            setOpenPeriodFilter(false)
        }
    }

    const periodOptions = ["Today", "Last 7 days", "This month", "Last month", "This year", "Last year"]
    const timePeriod = displayTimePeriod()

    const periodFilterOptions = (
        <div className={classNames("statistic-period-cont", {"active": openPeriodFilter})}>
            <div onClick={() => setOpenPeriodFilter(!openPeriodFilter)} className="statistic-period">
                <VscCalendar />
                {timePeriod}
            </div>
            {openPeriodFilter && (
                <div className="statistic-period-options-cont">
                    <div className="statistic-period-options">
                        {nonUrlParams && (
                            <div
                                onClick={onSetAllTime} 
                                className={classNames("period-option-single", {"active": timePeriod === "All time"})}
                                >
                                All time
                            </div>
                        )}
                        {periodOptions.map((p) => {
                            return (
                                <div
                                    key={p} 
                                    onClick={() => onChangePeriod(p)} 
                                    className={classNames("period-option-single", {"active": timePeriod === p})}
                                    >
                                    {capitalizeFirstLetter(p)}
                                </div>
                            )
                        })}
                    </div>
                    <DatePicker 
                        selected={customStartDate}
                        startDate={customStartDate}
                        endDate={customEndDate}
                        selectsRange
                        inline
                        onChange={onChangeCustomDate}
                        dateFormat="yyyy-MM-dd"
                        showYearDropdown
                        scrollableYearDropdown
                    />
                </div>
            )}
        </div>
    )
    
    return {
        from,
        to,
        periodFilterOptions
    }
}