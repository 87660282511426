import React, {useState, useEffect, useRef} from "react"
import { useMediaQuery } from "react-responsive";
import { capitalizeFirstLetter, ClickOutside } from "../../utility/Utilities";
import { useBackend, infrequentCacheConf } from "../../utility/Backend";
import { paramTypeDict } from "./TaggingUtil";
import classNames from "classnames";
import "./Tagging.css"
import { IoMdArrowDropright, IoMdArrowRoundBack } from "react-icons/io";

function PlayerParamOptions ({taggingState, game, onDispatchTagParam, editingParam}) {

    const {data: gamePlayers} = useBackend(game? ("/game/" + game.id + "/players") : null, {}, infrequentCacheConf)

    const homeTeamPlayers = gamePlayers?.home_team.players || []
    const awayTeamPlayers = gamePlayers?.visiting_team.players || []
    const homeTeam = game.home_team
    const awayTeam = game.visiting_team

    const playersList = () => {
        if (!taggingState.team) return []
        const homeTeamSelected = taggingState.team.id === homeTeam.id
        const awayTeamSelected = taggingState.team.id === awayTeam.id

        if (homeTeamSelected) {
            if (["offending player", "keeper"].includes(editingParam.name)) {
                return awayTeamPlayers
            } else {
                return homeTeamPlayers
            }
        }
        if (awayTeamSelected) {
            if (["offending player", "keeper"].includes(editingParam.name)) {
                return homeTeamPlayers
            } else {
                return awayTeamPlayers
            }
        }
        else return []
    }

    const playersInList = playersList()

    if (!taggingState.team) return (
        <div className="tagging-param-options">
            <div className="select-team-show-players">Select team to show players</div>
        </div>
    )

    if (taggingState.team && playersInList.length === 0) return (
        <div className="tagging-param-options">
            <div className="select-team-show-players">No players found</div>
        </div>
    )

    // All players that are selected and are in tagging state
    const playersInTaggingState = Object.keys(taggingState).reduce((list, key) => {
        if (taggingState[key]?.name) list.push({param: key, id: taggingState[key].id})
        return list
    }, [])
    
    return (
        <div className="tagging-param-options">
            {playersInList.map((player) => {
                const playerInTaggingState = playersInTaggingState.find((p) => p.id === player.id)
                const paramName = paramTypeDict[editingParam.name] || editingParam.name
                const playerSelectedInList = playerInTaggingState?.param === paramName
                return (
                    <div 
                        key={player.id} 
                        onClick={() => onDispatchTagParam(player)}
                        className={classNames("param-option-single", {"active": playerSelectedInList})}
                        >
                        <div className="param-option-player-number">{player.shirt_number}</div>
                        <div className="param-option-player-name">{player.name}</div>
                        {playerInTaggingState && (
                            <div className="param-option-player-selected">{capitalizeFirstLetter(playerInTaggingState.param).replace("_", " ")}</div>
                        )}
                    </div>
                )
            })}
        </div>
    )
}

function TeamParamOptions ({taggingState, onDispatchTagParam, game}) {

    const homeTeam = game.home_team
    const awayTeam = game.visiting_team
    const teams = [homeTeam, awayTeam]

    return (
        <div className="tagging-param-options team">
            {teams.map((team) => {
                return (
                    <div 
                        key={team.id} 
                        onClick={() => onDispatchTagParam(team)}
                        className={classNames("param-option-single", {"active": taggingState.team?.id === team.id})}
                        >
                        <img src={team.logo_url} className="param-option-team-logo" alt="team logo" />
                        <div className="param-option-team-name">{team.name}</div>
                    </div>
                )
            })}
        </div> 
    )
}

function TextParamInput ({taggingState, onDispatchTagParam, editingParam}) {

    const inputRef = useRef(null)
    useEffect(() => {
        if (editingParam?.type === "none" && inputRef.current) inputRef.current.focus()
    }, [taggingState])

    const paramName = paramTypeDict[editingParam.name] || editingParam.name
    const paramNameDisplay = capitalizeFirstLetter(editingParam.name.replace("_", " "))

    return (
        <div className="tagging-param-options input">
            <div className="input-container">
                <label className="input-title">{paramNameDisplay}</label>
                <input 
                    ref={inputRef}
                    type="text"
                    onChange={(e) => onDispatchTagParam(e.target.value)}
                    value={taggingState[paramName]}
                    className="tagging-param-input"
                    />
            </div>
        </div>
    )
}

function ListParamOptions ({taggingState, onDispatchTagParam, options, editingParam}) {
        
    const optionsInTaggingState = Object.keys(taggingState).reduce((list, key) => {
        if (typeof taggingState[key] === "string") list.push({param: key, value: taggingState[key]})
        return list
    }, [])

    return (
        <div className="tagging-param-options">
            {options.map((option) => {
                const optionSelectedInTaggingState = optionsInTaggingState.find((o) => o.value === option)
                const paramName = paramTypeDict[editingParam.name] || editingParam.name
                const optionSelectedInList = optionSelectedInTaggingState?.param === paramName
                return (
                    <div 
                        key={option} 
                        onClick={() => onDispatchTagParam(option)}
                        className={classNames("param-option-single", {"active": optionSelectedInList})}
                        >
                        {capitalizeFirstLetter(option)}
                    </div>
                )
            })}
        </div>
    )
}

function RenderTagParamOptions ({
    taggingState, 
    taggingDispatch, 
    editingParam, 
    editingParamIndex, 
    setEditingParamIndex, 
    game, 
    tagsData,
}) {

    const mobileDevice = useMediaQuery({maxWidth: 768})

    if (!editingParam) return null

    const tags = tagsData?.tags || []
    const tagParam = tags[taggingState.tag] || []
    const tagsDataTypes = tagsData?.types || []
    const tagType = tagsDataTypes[editingParam.type] || []

    if (!tagType) return null

    const onDispatchTagParam = (payload) => {

        const paramName = paramTypeDict[editingParam.name] || editingParam.name
        const paramType = editingParam.type

        // remove the value if selecting the same param
        if (taggingState[paramName] === payload) {
            taggingDispatch({type: paramName, payload: ""})
        } else {
            taggingDispatch({type: paramName, payload: payload})
        }

        if (mobileDevice) {
            setEditingParamIndex(null)
        } else {
            // no auto jump when it is a text
            if (paramType === "none") return
            // auto jump to next param selection after selecting a param value
            if (tagParam.length-1 > editingParamIndex) {
                setEditingParamIndex(editingParamIndex + 1)
            } else {
                setEditingParamIndex(null)
            }
        }
    }

    const mobileParamHeader = (mobileDevice && editingParam) && (
        <div className="param-options-mobile-header">
            <div className="param-options-mobile-header-title">{editingParam.name}</div>
            <div onClick={() => setEditingParamIndex(null)} className="param-options-mobile-header-back">
                <IoMdArrowRoundBack/>
                Back
            </div>
        </div>
    )

    let paramOptions
    const tagTypeOptions = tagType.options

    if (editingParam.type === "player") paramOptions = (
        <PlayerParamOptions
            taggingState={taggingState} 
            game={game}
            onDispatchTagParam={onDispatchTagParam} 
            editingParam={editingParam} 
            />
    )

    if (editingParam.type === "team") paramOptions = (
        <TeamParamOptions
            taggingState={taggingState}
            onDispatchTagParam={onDispatchTagParam}
            game={game} />
    )

    if (editingParam.type === "none") paramOptions = (
        <TextParamInput
            taggingState={taggingState} 
            onDispatchTagParam={onDispatchTagParam} 
            editingParam={editingParam}/>
    )

    if (tagTypeOptions.length !== 0) paramOptions = (
        <ListParamOptions 
            taggingState={taggingState}
            onDispatchTagParam={onDispatchTagParam}
            options={tagTypeOptions}
            editingParam={editingParam}/>
    )

    return (
        <div className={classNames("tagging-param-options-cont", {"show": editingParam})}>
            {mobileParamHeader}
            {paramOptions}
        </div>
    )
}

function TaggingParamProperties ({taggingState, taggingDispatch, game, tagsData}) {

    const [editingParamIndex, setEditingParamIndex] = useState(null)
    const taggingParamRef = useRef(null)
    ClickOutside(taggingParamRef, setEditingParamIndex, true)

    const tags = tagsData?.tags || []
    const tagParam = tags[taggingState.tag] || []
    const editingParam = tagParam[editingParamIndex]

    const onSelectEditingParam = (idx) => {
        if (editingParamIndex === idx) setEditingParamIndex(null)
        else setEditingParamIndex(idx)
    }
    console.log(tagParam);
    const renderTagParam = (
        <div className="tagging-tag-param">
            {tagParam.map((p, idx) => {
                let paramName = p.name
                let paramValue
                if (p.type === "team") {
                    paramValue = taggingState[paramName]?.name
                } 
                else if (p.type === "player") {
                    const tagParamName = taggingState[paramTypeDict[paramName]] || taggingState[paramName] || {}
                    const shirtNumber = tagParamName.shirt_number
                    const playerName = tagParamName.name
                    if (shirtNumber && playerName) {
                        paramValue = `${shirtNumber}, ${playerName}`
                    }
                } else {
                    paramValue = taggingState[paramTypeDict[paramName]] || taggingState[paramName]
                }

                return (
                    <div 
                        key={paramName} 
                        onClick={() => onSelectEditingParam(idx)}
                        className={classNames("tag-param-single", {"active": editingParam?.name === paramName})}
                        >
                        <div className="tag-param-single-title">{capitalizeFirstLetter(paramName.replace("_", " "))}</div>
                        <div className="tag-param-single-value">{paramValue}</div>
                        <IoMdArrowDropright/>
                    </div>
                )
            })}
        </div>
    )

    return (
        <div className="tagging-params-cont">
            <div className="tagging-section-title">Event Properties</div>
            <div ref={taggingParamRef} className="tagging-params-and-options">
                {renderTagParam}
                <RenderTagParamOptions
                    taggingState={taggingState} 
                    taggingDispatch={taggingDispatch} 
                    editingParam={editingParam} 
                    editingParamIndex={editingParamIndex} 
                    setEditingParamIndex={setEditingParamIndex} 
                    game={game} 
                    tagsData={tagsData}
                    />
            </div>
        </div>
    )
}

export default TaggingParamProperties