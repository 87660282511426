import React, {useState, useRef} from "react"
import { ClickOutside } from "../utility/Utilities";
import { capitalizeFirstLetter } from "../utility/Utilities";
import {TiArrowSortedDown} from "react-icons/ti";
import "../components/css/SmallDropdown.css";
import classNames from "classnames";
function SmallDropdown({ value, options, onChange, noneOption = false, children = null }) {
    
    const dropdownRef = useRef(null)
    
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)

    ClickOutside(dropdownRef, setIsDropdownOpen)

    if (!value) value = "None"

    const onClickChange = (option) => {
        onChange(option)
        setIsDropdownOpen(false)
    }

    // TODO if wanna put preview video for package form
    // const childrenContInDropdown = (
    //     <div className="children-in-dropdown">
    //         {children}
    //     </div>
    // )

    return (
        <div ref={dropdownRef} className="dropdown-menu-cont">
            <div onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="dropdown-selected">
                {capitalizeFirstLetter(value)}
                <TiArrowSortedDown />
            </div>

            {isDropdownOpen && (
                <div className="dropdown-list-cont">
                    {noneOption && (
                        <div
                            onClick={() => onClickChange(null)}
                            className={classNames("dropdown-item-single", { "active": value === "None" })}
                        >
                            None
                        </div>
                    )}
                    {options.map((option) => {
                        // Ensure we're passing a string, not an object
                        const optionLabel = typeof option === 'object' ? option.label : option
                        return (
                            <div
                                key={optionLabel}
                                onClick={() => onClickChange(option)}
                                className={classNames("dropdown-item-single", { "active": value === optionLabel })}
                            >
                                {capitalizeFirstLetter(optionLabel)}
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

export default SmallDropdown