import React, { useState, useEffect } from "react"
import SmallDropdown from "../../components/SmallDropdown"
import Backend from "../../utility/Backend"
import "./ShareVideo.css"

function CaptionOptions({ caption, video, onCaptionGenerated, platform }) {
    const [tone, setTone] = useState("neutral")
    // const [teamFavor, setTeamFavor] = useState("neutral")
    const [rivalry, setRivalry] = useState("neutral")
    const [language, setLanguage] = useState("Norwegian")
    const [generatedCaption, setGeneratedCaption] = useState("")
    const [error, setError] = useState(null)

    const onGenerateCaption = async () => {
        const requestBody = {
            platform: platform.toLowerCase(),
            user_description: caption.toLowerCase(),
            tone,
            language: language.toLowerCase(),
            rivalry,
            original_title: video.description.toLowerCase() || "video title",
        }

        try {
            const response = await Backend.post("/socials/generate_post_description", JSON.stringify(requestBody))
            if (response.data.description) {
                setGeneratedCaption(response.data.description)
                onCaptionGenerated(response.data.description)
            } else {
                setError("Failed to generate caption")
            }
        } catch (error) {
            console.error("Error generating caption:", error)
            setError("Failed to generate caption")
        }
    }

    return (
        <div className="caption-options">
            <div className="caption-options-dropdown">
                <div className="input-row-caption">
                    <div className="input-container-caption">
                        <label className="input-title">Platform</label>
                        <SmallDropdown
                            value={platform}
                            options={[{ value: platform, label: platform }]}
                            disabled={true}
                        />
                    </div>

                    <div className="input-container-caption">
                        <label className="input-title">Tone</label>
                        <SmallDropdown
                            value={tone}
                            options={[
                                { value: "neutral", label: "Neutral" },
                                { value: "formal", label: "Formal" },
                                { value: "casual", label: "Casual" }
                            ]}
                            onChange={(selectedOption) => setTone(selectedOption.value)}
                        />
                    </div>
                </div>
                <div className="input-row-caption">
                    {/* <div className="input-container-caption">
                        <label className="input-title">Team Favor</label>
                        <SmallDropdown
                            value={teamFavor}
                            options={[
                                { value: "Neutral", label: "Neutral" },
                                // { value: "Home", label: "Home" },
                                // { value: "Away", label: "Away" }
                            ]}
                            onChange={(selectedOption) => setTeamFavor(selectedOption.value)}
                        />
                    </div> */}

                    <div className="input-container-caption">
                        <label className="input-title">Language</label>
                        <SmallDropdown
                            value={language}
                            options={[
                                { value: "Norwegian", label: "Norwegian" },
                                { value: "Swedish", label: "Swedish" },
                                { value: "English", label: "English" },
                            ]}
                            onChange={(selectedOption) => setLanguage(selectedOption.value)}
                        />
                    </div>

                    <div className="input-container-caption">
                        <label className="input-title">Rivalry</label>
                        <SmallDropdown
                            value={rivalry}
                            options={[
                                { value: "neutral", label: "Neutral" },
                                { value: "peaceful", label: "Peaceful" },
                                { value: "rivalry", label: "Rivalry" }
                            ]}
                            onChange={(selectedOption) => setRivalry(selectedOption.value)}
                        />
                    </div>
                </div>
            </div>

            <button onClick={onGenerateCaption} className="generate-caption-btn">Generate</button>

            {/* Display generated caption */}
            {generatedCaption && (
                <div className="generated-caption">
                    <label className="input-title">Generated Caption</label>
                    <p>{generatedCaption}</p>
                </div>
            )}

            {error && <div className="error-message">{error}</div>}
        </div>
    )
}

export default CaptionOptions
