import React from "react"
import { useUpdateSearchParams } from "../utility/Utilities";
import classNames from "classnames"
import {FaTimes} from "react-icons/fa";
import "./css/FullscreenFilter.css"

function FullscreenFilter ({ showFilter, onSetFilter, children }) {

    const [,,resetAllSearchParamsExcept] = useUpdateSearchParams();

    return (
        <div className={classNames("fullscreen-filters-cont", {"view": showFilter})}>
            <div className="fullscreen-title-and-close">
                <div className="fullscreen-filter-title">Filter</div>
                <div onClick={() => onSetFilter(false)} className="fullscreen-filter-close">
                    <FaTimes/>
                    Close
                </div>
            </div>
            <div className="all-filters-cont">
                {children}
            </div>
            <button onClick={() => resetAllSearchParamsExcept([])} className="clear-filter-btn">
                Clear all
            </button>
        </div>
    )
}

export default FullscreenFilter