import React, {useEffect, useState, useRef} from "react";
import {useLoginSession} from "../stores/loginSession";
import Config from "../utility/Config";
import { ClickOutside } from "../utility/Utilities";
import { IoMdArrowDropdown } from "react-icons/io"
import { FiDownload } from "react-icons/fi"

function DirectDownload ({
    filename=undefined,
    duration=undefined,
    videoUrl=undefined,
}) {

    const {token} = useLoginSession()
    const directDownloadRef = useRef(null)

    const [downloadTypesOpen, setDownloadTypesOpen] = useState(false)
    const [isDownloading, setIsDownloading] = useState(null)

    useEffect(() => {
        if (!isDownloading) return
        const downloadTimeout = setTimeout(() => {
            if (isDownloading) setIsDownloading(null);
        }, 10000);
        return () => clearTimeout(downloadTimeout);
    }, [isDownloading]);

    ClickOutside(directDownloadRef, setDownloadTypesOpen)

    const isTooLong = duration > Config.maximumExportSeconds;

    function onDirectDownload (type) {
        
        // prevent double click when downloading
        if (isDownloading) return

        const filenameAndExtension = filename + type
        // TODO This is a bit hacky, but the videourl reported by the backend isn't
        //      always the correct domain, and therefore might not be the one the login session is for
        const url = videoUrl
            .replace("Manifest.m3u8","50000000/" + filenameAndExtension)
            .replace(/https:\/\/[^.]*api\..*\.com\//, Config.api)

        setIsDownloading(type)
        setDownloadTypesOpen(false)
    
        if (Config.premiereProPlugin) {
            const pl = {
                mp4_url: url,
                id: filename,
            };
            const msg = { at: token, videos: [pl] };
            console.log("Posting:", "playlists_" + JSON.stringify(msg));
            window.top.postMessage("playlists_" + JSON.stringify(msg), "*");
            return;
        }
    
        // Note: I do not like exposing the access token in the DOM
        // + it's easier to control a div than an anchor
        const a = document.createElement("a");
        a.href = url + "?access_token=" + token;
        a.download = filenameAndExtension;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    return (
        <div ref={directDownloadRef} className="direct-download-cont">
            {isDownloading ? (
                <button disabled>
                    Downloading {isDownloading}
                </button>
            ) : (
                <button 
                    onClick={() => setDownloadTypesOpen(!downloadTypesOpen)} 
                    disabled={isTooLong}
                    className="direct-download-option"
                    >
                    <FiDownload className="icon-in-btn"/>
                    Direct download
                    <IoMdArrowDropdown/>
                </button>
            )}
            {downloadTypesOpen && (
                <div className="download-types-cont">
                    <button onClick={() => onDirectDownload(".mp4")} className="download-type-single">
                        fMP4
                    </button>
                    <button onClick={() => onDirectDownload(".ts")} className="download-type-single">
                        TS - PPro compatible
                    </button>
                </div>
            )}
        </div>
    )
}

export default DirectDownload