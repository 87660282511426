import React, {useEffect} from "react";
import Layout from "./pages/Layout/Layout";
import {Routes, Route, useNavigate} from "react-router-dom";
import Profile from "./pages/Profile/Profile";
import Users from "./pages/Users/Users";
import Streams from "./pages/Streams/Streams";
import TaggingPage from "./pages/Tagging/TaggingPage";
import Teams from "./pages/Teams/Teams";
import Library from "./pages/Library/Library";
import GraphicPackage from "./pages/Library/GraphicPackage/GraphicPackage";
import Config from "./utility/Config"
import {LoginSessionProvider} from "./stores/loginSession";
import EventListing from "./pages/Library/EventListing/EventListing";
import MatchListing from "./pages/Library/MatchListing/MatchListing";
import {SWRProvider} from "./utility/Backend";
import {HelmetProvider } from "react-helmet-async";
import PlaylistListing from "./pages/Library/PlaylistListing/PlaylistListing";
import Match from "./pages/Library/Match/Match";
import {FeedbackMessageProvider} from "./stores/FeedbackMessage";
import {CompilationProvider} from "./stores/compilation";
import { TaggingProvider } from "./stores/VideoTagging";
import SsoLoginCallbackPage from "./pages/Layout/SsoLoginCallbackPage";
import CustomRouter from "./pages/Layout/CustomRouter";
import {ExportsModalProvider} from "./stores/ExportsModalContext";
import Statistics from "./pages/Statistics/Statistics";
import Search from "./components/Search";
import Privacy from "./pages/Privacy/Privacy";
import Terms from "./pages/Terms/Terms";
import { RequestResetPassword } from "./pages/Login/ResetPassword";
import { ResetPassword } from "./pages/Login/ResetPassword";

function RedirectHome () {
    let navigate = useNavigate();
    useEffect(() => {
        navigate(Config.defaultPath, {replace: true});
    });
    return null;
}

function App () {

    const {pathname, search} = window.location;
    let baseName = null;
    Config.leagues.forEach((league) => {
        if (pathname.startsWith("/" + league.path)) {
            baseName = league.path;
            Config.activeLeague = league;
        }
    });
    if (baseName === null) {
        // SSO login must use /login/callback, no basename prefix, complicating matters
        if (pathname === "/login/callback") {
            const params = new URLSearchParams(search);
            baseName = "/" + params.get("state").split("/")[1];
            params.set("state", params.get("state").slice(baseName.length))
            window.location = baseName + "/login/callback?" + params.toString();
            return null
        }
        window.location = "/" + Config.leagues[0].path;
        return null;
    }

    return (
        <SWRProvider>
            <HelmetProvider>
                <LoginSessionProvider>
                    {/*<BrowserRouter basename={`/${baseName}`}>*/}
                    <CustomRouter basename={`/${baseName}`}>
                        <FeedbackMessageProvider>
                            <ExportsModalProvider>
                                <CompilationProvider>
                                    <TaggingProvider>
                                        <Routes>
                                            <Route path="privacy" element={<Privacy />} />
                                            <Route path="terms" element={<Terms />} />
                                            <Route path="/login/callback" element={<SsoLoginCallbackPage />} />
                                            <Route path="/" element={<Layout />}>
                                                <Route index element={<RedirectHome />} />
                                                <Route path="library" element={<Library />}>
                                                    <Route index element={<RedirectHome />} />
                                                    <Route path="match/:gameId" element={<Match />} />
                                                    <Route path="events" element={<EventListing />} />
                                                    <Route path="matches" element={<MatchListing />} />
                                                    <Route path="videos" element={<PlaylistListing />} />
                                                    <Route path="search" element={<Search />} />
                                                </Route>
                                                <Route path="tagging" element={<TaggingPage />} />
                                                <Route path="teams" element={<Teams />} />
                                                <Route path="streams" element={<Streams />} />
                                                <Route path="statistics" element={<Statistics />} />
                                                <Route path="graphic_packages" element={<GraphicPackage />} />
                                                <Route path="users" element={<Users />} />
                                                <Route path="profile" element={<Profile />} />
                                                <Route index path="*" element={<RedirectHome />} />
                                            </Route>
                                            <Route path="passwordreset" element={<ResetPassword />} />
                                            <Route path="requestreset" element={<RequestResetPassword />} />
                                        </Routes>
                                    </TaggingProvider>
                                </CompilationProvider>
                            </ExportsModalProvider>
                        </FeedbackMessageProvider>
                    </CustomRouter>
                    {/*</BrowserRouter>*/}
                </LoginSessionProvider>
            </HelmetProvider>
        </SWRProvider>
    );
}

export default App;