import React, {useRef, useMemo} from "react"
import { useCheckIsAdmin } from "../../utility/UserGroups";
import { capitalizeFirstLetter } from "../../utility/Utilities";
import ThumbnailSelector from "../../components/ThumbnailSelector";
import TaggingParamProperties from "./TaggingParamProperties";
import classNames from "classnames";
import { DisplayPassedTaggingTime } from "./TaggingUtil";
import { AdjustTaggingTime } from "./TaggingUtil";
import "./Tagging.css"
import {FiCheckSquare, FiSquare} from "react-icons/fi";
import {BsInfoCircle} from "react-icons/bs";

function AssetTaggingForm ({taggingState, taggingDispatch, playlist, game, tagsData, onCreateTag}) {

    const [,isAdmin] = useCheckIsAdmin()
    const titleRef = useRef(null)
    const tags = tagsData?.tags || []
    const tagParam = tags[taggingState.tag] || []

    const videoAssetName = playlist?.events[0]?.video_asset_name 

    const clipsForThumbnails = useMemo(() => [{
        from: taggingState.from_timestamp,
        to: taggingState.to_timestamp,
        assetName: videoAssetName,
    }], [taggingState.from_timestamp, taggingState.from_timestamp])
    
    const setThumbnail = (newUrl) => {
        if (typeof newUrl === "object") taggingDispatch({type: "thumbnail_url", payload: newUrl})
        else taggingDispatch({type: "thumbnail_url", payload: newUrl})
    }

    return (
        <form className="tagging-form" onSubmit={(e) => e.preventDefault()}>
            <div className="confirm-cancel-btn-cont between">
                <button onClick={onCreateTag} disabled={!taggingState.tag} className="green-btn">
                    Create Event
                </button>
                <button onClick={() => taggingDispatch({type: "reset"})}>
                    Cancel
                </button>
            </div>
            {taggingState.tag && (
                <div className="tag-details-cont">
                    <div className="tagging-section-title">Event details</div>
                    <div className="tag-details">
                        <div className="tag-details-single">
                            <div className="tag-details-title">Event name</div>
                            <div className="tag-name">{capitalizeFirstLetter(taggingState.tag)}</div>
                        </div>
                        <div className="tag-details-single">
                            <div className="tag-details-title">Tagged at</div>
                            <div className="tagged-time">{new Date(taggingState.time).toLocaleTimeString()}</div>
                            <DisplayPassedTaggingTime taggingTime={taggingState.time}/>
                        </div>
                    </div>
                    <AdjustTaggingTime taggingState={taggingState} taggingDispatch={taggingDispatch}/>
                </div>
            )}
            {tagParam.length !==0 && (
                <TaggingParamProperties 
                    taggingState={taggingState} 
                    taggingDispatch={taggingDispatch}
                    game={game}
                    tagsData={tagsData}/>
            )}
            <div ref={titleRef} className="input-container">
                <label className="input-title">Title</label>
                <input
                    type="text"
                    value={taggingState.description}
                    onChange={(e) => taggingDispatch({type: "description", payload: e.target.value})}>
                </input>
            </div>
            {isAdmin && (
                <div className="input-container">
                    <label className="input-title">Published</label>
                    <>
                        <div
                            onClick={() => taggingDispatch({type: "is_private", payload: true})}
                            className={classNames("option-checkbox", {"active": taggingState.is_private})}>
                            {taggingState.is_private?
                                <FiCheckSquare className="check-box-icon"/> :
                                <FiSquare className="check-box-icon"/>}
                            <div className="option-box-title">
                                Not yet published
                                <div className="option-extra-message">
                                    <BsInfoCircle className="info-icon"/> video will be unlisted (can be changed later)
                                </div>
                            </div>
                        </div>
                        <div
                            onClick={() => taggingDispatch({type: "is_private", payload: false})}
                            className={classNames("option-checkbox", {"active": !taggingState.is_private})}>
                            {!taggingState.is_private?
                                <FiCheckSquare className="check-box-icon"/> :
                                <FiSquare className="check-box-icon"/>}
                            <div className="option-box-title">
                                Published
                            </div>
                        </div>
                    </>
                </div>
            )}
            <div className="input-container">
                <label className="input-title">Thumbnail</label>
                <ThumbnailSelector
                    selected={taggingState.thumbnail_url?.imageUrl || taggingState.thumbnail_url}
                    onChange={setThumbnail}
                    playlistClips={clipsForThumbnails}
                    />
            </div>
            <div className="confirm-cancel-btn-cont between">
                <button onClick={onCreateTag} disabled={!taggingState.tag} className="green-btn">
                    Create Event
                </button>
                <button onClick={() => taggingDispatch({type: "reset"})}>
                    Cancel
                </button>
            </div>
        </form>
    )
}

export default AssetTaggingForm