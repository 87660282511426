import React from "react"
import "./ExportProgress.css"

function ExportProgress ({percentage, status}) {
    
    const percentageNumber = percentage || 0
    const progressDegree = 3.6 * percentageNumber

    let progressBar = {
        background: "conic-gradient(var(--light-blue) 90deg,transparent 0deg)",
        animation: "rotate 2s linear infinite",
    }

    if (percentage) progressBar = {
        background: `conic-gradient(var(--light-blue) ${progressDegree}deg,transparent 0deg)`
    }

    if (status === "FAILED") return (
        <div className="export-unavailable">
            Export failed
        </div>
    )

    return (
        <div className="export-progress-main">
            <div className="export-progress">
                <div className="export-progress-bar" style={progressBar}></div>
                <div className="export-progress-percentage">{percentageNumber}%</div>
            </div>
            <div className="export-info-cont">
                {percentage ? (
                    <div className="export-progress-status">
                        Exporting
                        <div className="export-progress-title-dot">.</div>
                        <div className="export-progress-title-dot">.</div>
                        <div className="export-progress-title-dot">.</div>
                    </div>
                ) : (
                    <div className="export-progress-status">
                        Starting...
                    </div>
                )}
                <div className="export-processing-time-info">This may take a few minutes</div>
            </div>
        </div>
    )
}

export default ExportProgress