import React, {useEffect, useReducer} from "react";
import "./EditingControls.css";
import EditingTimeline from "./EditingTimeline";
import {useVideoPlayer} from "../../../../components/VideoPlayer/VideoPlayerProvider";
import {useIsMount} from "../../../../utility/Utilities";
import Config from "../../../../utility/Config";
import { useCompilation } from "../../../../stores/compilation";

function initClipReducer ({clip, duration, padding}) {
    const clipDuration = (clip.to_timestamp - clip.from_timestamp) / 1000;

    if (clip.state) {
        return {
            duration: duration,
            start: Math.min(clip.state.start, duration),
            end: Math.min(clip.state.end, duration),
        }
    }
    return {
        duration: duration,
        start: Math.min(duration, padding),
        end: Math.min(duration, padding + clipDuration),
    }
}

function reducer (state, action) {
    const {duration, start, end} = state;

    switch (action.type) {
        case "reset": {
            return initClipReducer(action.props);
        }
        case "setStart": {
            const position = Math.max(0, Math.min(duration, action.position));
            return {
                duration,
                start: position,
                end: Math.max(end, Math.min(duration, position + 2)),
            }
        }
        case "setEnd": {
            const position = Math.max(0, Math.min(duration, action.position));
            return {
                duration,
                start: Math.min(start, Math.max(0, position - 2)),
                end: position,
            }
        }
        default: throw new Error("Unsupported reducer action " + action.type);
    }
}

export default function EditClipInterval ({
    clip,
    padding=Config.paddingDuringEditing,
    withButtons=false, // If true, then onSave & onDiscard must be provided
    onSave=null,
    onDiscard=null,
    onChange=null,
    isCompilation=false,
}) {

    let {
        duration,
        seekTo,
        getCurrentTime,
        playbackUrl,
    } = useVideoPlayer();

    const {updateClipDuration} = useCompilation();

    if (duration === 0) duration = 0.0001; // Super edge case

    const currentTime = getCurrentTime()
    const isMount = useIsMount();
    const [state, dispatch] = useReducer(reducer, {clip, duration, padding}, initClipReducer);
    const {start, end} = state;

    useEffect(() => {
        if (!isMount) dispatch({type: "reset", props: {clip, duration, padding}});
    }, [clip, duration]);

    useEffect(() => {
        if (!isMount && onChange) {
            onChange({
                start,
                end,
                from_timestamp: clip.from_timestamp + parseInt(1000 * (start - padding)),
                to_timestamp: clip.from_timestamp + parseInt(1000 * (end - padding)),
            });
        }
        if (isCompilation) updateClipDuration(clip)
    }, [start, end]);

    // Seek for single clip
    useEffect(() => {
        if (!isCompilation) seekTo(start)
    }, [])

    // Seek for compilations, when changing the clip
    useEffect(() => {
        if (isCompilation && currentTime === 0) seekTo(start)
    }, [isCompilation, currentTime])

    function onClickSave () {
        const splitUrl = playbackUrl.split("/");
        const offsetMs = parseInt(splitUrl[splitUrl.length - 2].split(":")[1]);
        const newClip = {
            ...clip,
            from_timestamp: offsetMs + parseInt(1000 * start),
            to_timestamp: offsetMs + parseInt(1000 * end),
        }
        if (clip.from_timestamp === newClip.from_timestamp &&
            clip.to_timestamp === newClip.to_timestamp) return onDiscard();

        onSave(newClip);
    }

    return (
        <>
            <EditingTimeline
                timelineStart={0}
                timelineEnd={duration}
                start={start}
                end={end}
                setStart={(s) => dispatch({type: "setStart", position: s})}
                setEnd={(s) => dispatch({type: "setEnd", position: s})}
                getCurrentPlaybackTime={getCurrentTime}
                seekTo={seekTo}
            />
            {withButtons && (
                <div className="confirm-cancel-btn-cont between">
                    <button className="green-btn" onClick={onClickSave}>
                        Save
                    </button>
                    <button onClick={onDiscard}>
                        Discard
                    </button>
                </div>
            )}
        </>
    );
}