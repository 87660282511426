import React, {useState, useRef} from "react";
import { ClickOutside } from "../utility/Utilities";
import { useMediaQuery } from "react-responsive";
import "./css/Dropdown.css";
import "./css/ListingFilter.css";
import { IoMdArrowDropdown } from "react-icons/io"
import { FiChevronRight } from "react-icons/fi";
import { TfiArrowLeft } from "react-icons/tfi";
import classNames from "classnames";

export default function Dropdown ({
    options, // required, array of strings
    fallback, // required, default value
    onChange, // required
    render = null, // render function taking id as param. E.g. (id) => options[id].name
    btnRender = null, // render function for the button. Falls back to render
    selected = null,
    disabled = false,
    title = null,
    special = null, // an option not in the normal list of options, will be listed first. Usually "all"
}) {
    const [open, setOpen] = useState(false);
    
    const dropdownRef = useRef(null)
    
    ClickOutside(dropdownRef, setOpen)

    const mobileDevice = useMediaQuery({maxWidth: 768})

    selected = selected ? selected : fallback;

    function displayName (id) {
        
        if (id === "goalkeeperevent") return "goalkeeper event"
        if (id === "shootoutpenaltyshot") return "shoot out penalty"

        if (id === special || !render) return id;
        return render(id);
    }

    function displayBtnName (id) {
        try {
            if (!btnRender) return displayName(id);
            return (id === special) ? id : btnRender(id);
        } catch (e) {
            //This happens typically if the selected option isnt in the list of options
            console.error(e);
            return id;
        }
    }

    if (disabled) {
        if (mobileDevice) {
            return (
                <div className="filter-single-mobile">
                    <div className="filter-single-and-selected">
                        <div className="filter-title-mobile">{title}</div>
                        <div className="filter-selected-mobile disabled">
                            {displayBtnName(selected)}
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="filter-dropdown disabled">
                    {title && (
                        <div className="filter-title">{title}</div>
                    )}
                    <div className="filter-selected">
                        {displayBtnName(selected)}
                    </div>
                </div>
            )
        } 
    }

    function renderItem (id, idx) {
        function onClick () {
            onChange(id === fallback ? null : id);
            setOpen(false);
            return false;
        }
        
        return (
            <li key={id}
                tabIndex={idx+1}
                className={classNames("dropdown-item", {
                    "active": id.toString() === selected.toString(),
                    "mobile": mobileDevice,
                })}
                onClick={onClick}
                >
                {displayName(id)}
            </li>
        );
    }

    if (mobileDevice) return (
        <div className="mobile-dropdown-cont">
            <div onClick={() => setOpen(!open)} className="filter-single-mobile">
                <div className="filter-single-and-selected">
                    <div className="filter-title-mobile">{title}</div>
                    {!!displayBtnName(selected) && (
                        <div className="filter-selected-mobile">
                            {displayBtnName(selected)}
                        </div>
                    )} 
                </div>
                <FiChevronRight/>
            </div>
            <ul className={classNames("filter-mobile-dropdown-list", {"open": open})}>
                <div onClick={() => setOpen(false)} className="filter-mobile-dropdown-back">
                    <div className="filter-mobile-dropdown-title">{title}</div>
                    <div className="filter-mobile-back">
                        <TfiArrowLeft/>
                        Back
                    </div>
                </div>
                {(special && renderItem(special, -1))}
                {options.map(renderItem)}
            </ul>
        </div>
    )

    return (
        <div
            ref={dropdownRef}
            onClick={() => setOpen(!open)} 
            className={classNames("filter-dropdown", {
                "open": open,
                "narrow": title === "Season" || title === "Round",
                "wide": title === "Channel" || title === "Player"
            })}>
            <div className="filter-title">{title}</div>
            <div className="filter-selected">
                {displayBtnName(selected) || "-"}
                <span className="dropdown-arrow"><IoMdArrowDropdown /></span>
            </div>
            <ul className="filter-dropdown-list">
                {special && renderItem(special, -1)}
                {options.map(renderItem)}
            </ul>
        </div>
    );
}