import {useMutateByRegex, useUpdateSearchParams} from "../../../utility/Utilities";
import React, {useState, useEffect} from "react";
import {createDateFilter} from "../EventListing/EventListing";
import {useLoginSession} from "../../../stores/loginSession";
import {useGetCategories} from "../../../utility/UseGetCategories";
import {useGetChannels} from "../../../utility/UseGetChannels";
import {getFullPath} from "../../../utility/Backend";
import FullscreenFilter from "../../../components/FullscreenFilter";
import { useMediaQuery } from "react-responsive";
import SearchResults from "../../../components/SearchResults";
import Dropdown from "../../../components/Dropdown";
import PlaylistEntry from "../../../components/PlaylistEntry";
import { useAddVideoToAction } from "../../../utility/Utilities";
import { PiListChecksBold } from "react-icons/pi";
import { IoMdOptions } from "react-icons/io";

const dateMinutesRounding = (floor=false) => {
    const timestamp = new Date().getTime()
    const fifteenMinutes = 15 * 60 * 1000

    let timestampRounded = Math.ceil(timestamp / fifteenMinutes) * fifteenMinutes
    if (floor) timestampRounded = Math.floor(timestamp / fifteenMinutes) * fifteenMinutes

    const dateISO = new Date(timestampRounded).toISOString()
    
    return dateISO
} 

const typeFilters = {
    "all": (query) => query,
    "upcoming streams": (query) => {
        query["from_date"] = dateMinutesRounding(true);
        query["to_date"] = undefined;
        return query
    },
    "past streams": (query) => {
        query["filters"] = ["livestream"];
        return query
    },
    "uploads": (query) => {
        query["master_playlist"] = true;
        query["filters"] = ["upload"];
        return query
    },
    "compilations": (query) => {
        query["filters"] = ["playlist"];
        return query
    },
    "featured": (query) => {
        query["only_featured"] = true;
        return query
    },
}

function createCategoryFilter (query, category, categories, updateSearchParams) {

    if (categories === null) {
        return [query, null];
    }
    if (category) {
        query["tags"] = {"action": category}
    }
    
    // TODO need to decide later if this is temporarily or can be permanent?
    const selected = category && categories[category]
    let options = Object.values(categories).sort((a,b) => a.localeCompare(b))

    const updateCategory = (categories, v) => {
        const category = Object.keys(categories).find(key => categories[key] === v)
        updateSearchParams("tags", category)
    }
    
    return [query, (
        <div>
            <Dropdown
                title="Category"
                centered
                special="all"
                fallback="all"
                selected={selected}
                options={options}
                onChange={v => updateCategory(categories, v)}/>
        </div>
    )];
}

function createChannelFilter (query, channel, channels, updateSearchParams) {

    channels = channels || []

    if (channel) {
        query["channels"] = channel
    }

    let options = Object.keys(channels)
    options.sort((a,b) => channels[a].name.localeCompare(channels[b].name))

    return [query, (
        <div>
            <Dropdown
                title="Channel"
                centered
                special="all"
                fallback="all"
                selected={channel}
                options={options}
                render={(id) => channels[id]?.name || ""}
                btnRender={(id) => channels[id]?.name || ""}
                onChange={v => updateSearchParams("channels", v)}/>
        </div>
    )];
}

export default function PlaylistListing () {
    const {token} = useLoginSession();
    const [searchParams, updateSearchParams,] = useUpdateSearchParams();
    const categoriesList = useGetCategories()
    const channelsList = useGetChannels()

    const [
        selectedVideos,
        selectAll,
        addVideoToList, 
        removeVideoFromList, 
        clearSelectedList, 
        handleSelectAll 
    ] = useAddVideoToAction();

    const mutateByRegex = useMutateByRegex()

    // TODO temporary, mutate playlist first time landing on this page
    useEffect(() => {mutateByRegex(/^\/playlist\//)}, [])

    const [mobileShowAction, setMobileShowAction] = useState(false)
    const [mobileShowFilters, setMobileShowFilters] = useState(false)
    
    const category = searchParams.get("tags")
    const channel = searchParams.get("channels")

    let path = "/playlist/editable";
    let apiQuery = {
        access_token: token,
        all_leagues: true,
        include_featured_flag: true,
        include_channels: true,
        orderby: "created",
        to_date: dateMinutesRounding(),
    };
    let dateFilter, typeFilter, categoryFilter, channelFilter;

    [apiQuery, dateFilter] = createDateFilter(apiQuery, searchParams, updateSearchParams);

    const videoTypes = Object.keys(typeFilters);
    let videoType = searchParams.get("videotype") || videoTypes[0];
    if (!videoTypes.includes(videoType)) videoType = videoTypes[0];
    typeFilter = (
        <div>
            <Dropdown
                title="Video type"
                centered
                fallback={videoTypes[0]}
                selected={videoType}
                options={videoTypes}
                onChange={v => updateSearchParams("videotype", v)}/>
        </div>
    );
    
    let channels = channelsList
    if (channelsList) {
        channels = channels.reduce((agg, c) => {agg[c.id] = c; return agg;}, {});
    }

    [apiQuery, categoryFilter] = createCategoryFilter(apiQuery, category, categoriesList, updateSearchParams);
    [apiQuery, channelFilter] = createChannelFilter(apiQuery, channel, channels, updateSearchParams);
    
    if (videoType === "upcoming streams") path += "?asc"
    
    apiQuery = typeFilters[videoType](apiQuery);
    const apiPath = getFullPath(path, apiQuery);

    const editing = searchParams.get("editing");
    let editingType, editingId;
    if (editing) [editingType, editingId] = editing.split("_");

    const renderItem = (p, idx) => (
        <PlaylistEntry key={p.id}
                       playlist={p}
                       active={p.id === editingId && editingType === "playlist"}
                       tabIndex={20+idx}
                       selectedVideos={selectedVideos}
                       selectAll={selectAll}
                       addVideoToList={addVideoToList}
                       removeVideoFromList={removeVideoFromList}
                       mobileShowAction={mobileShowAction}/>
    );

    const handleMobileAction = () => {
        if (selectedVideos.length !== 0) clearSelectedList()
        setMobileShowAction(!mobileShowAction)
    }
    
    const mobileDevice = useMediaQuery({maxWidth: 768})

    const mobileVideoFilters = mobileDevice && (
        <FullscreenFilter showFilter={mobileShowFilters} onSetFilter={setMobileShowFilters}>
            <>
                {dateFilter}
                {typeFilter}
                {categoryFilter}
                {channelFilter}
            </>
        </FullscreenFilter>
    )

    const resultsOptionsMobile = mobileDevice && (
        <div className="results-options-mobile">
            <div onClick={handleMobileAction}>
                <PiListChecksBold/>
                {mobileShowAction ? "Cancel selection"  : "Select videos"}
            </div>
            <div onClick={() => setMobileShowFilters(true)}><IoMdOptions/> Filters</div>
        </div>
    )

    return (
        <div className="event-listing">
            <div className="event-filters">
                {dateFilter}
                {typeFilter}
                {categoryFilter}
                {channelFilter}
            </div>
            {mobileVideoFilters}
            {resultsOptionsMobile}
            <SearchResults 
                key={apiPath} 
                path={apiPath} 
                render={renderItem}
                selectedVideos={selectedVideos} 
                clearSelectedList={clearSelectedList}
                selectAll={selectAll}
                handleSelectAll={handleSelectAll}
                mobileShowAction={mobileShowAction}
                setMobileShowAction={setMobileShowAction}
                setMobileShowFilters={setMobileShowFilters}/>
        </div>
    );
}