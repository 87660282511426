import React, {useEffect, useState} from "react"
import Backend from "../../utility/Backend"
import Config from "../../utility/Config"
import CustomModal from "../../components/CustomModal"
import SmallDropdown from "../../components/SmallDropdown"
import { useLoginSession } from "../../stores/loginSession"
import { showErrorMessage } from "../../utility/Utilities"
import classNames from "classnames"
import { FaGoogle } from "react-icons/fa";
import { RiFileCopyLine } from "react-icons/ri";
import { MdOutlineSubtitles } from "react-icons/md";
import "./ShareVideo.css"

const categoryToId = {
    "sports": 17,
    "entertainments": 24,
    "educations": 27,
    "trailers": 44,
} 

function ShareToYoutube ({shareVideo, onClose, openShareTable}) {

    const {token} = useLoginSession()
    const video = shareVideo.video

    const [youtubeUser, setYoutubeUser] = useState(null)
    const [loggingOutGoogle, setLoggingOutGoogle] = useState(false)
    const [fetchingCredentials, setFetchingCredentials] = useState(false)
    
    const fetchYoutubeCredential = async () => {
        const {data: youtubeCredentials} = await Backend.get("/socials/credentials/youtube")
        if (!youtubeCredentials || youtubeCredentials?.length === 0) {
            setYoutubeUser(null)
        } else {
            setYoutubeUser(youtubeCredentials[0])
            setFetchingCredentials(false)
        }
    }

    // fetch youtube credential on first render to check if the user has already logged in google and has the credential
    useEffect(() => {
        fetchYoutubeCredential()
    }, [])

    // Fetch youtube credential every 3 secs when user triggers/opens google login
    // TODO need to handle a case when user opens the login but doesn't login / close the login window
    useEffect(() => {
        const fetchMoreFrequent = setInterval(()=> {
            if (fetchingCredentials) fetchYoutubeCredential()
        }, 3000)
        return () => clearInterval(fetchMoreFrequent);
    }, [fetchingCredentials])
    
    useEffect(() => {
        if (!formError) return
        if (youtubeUser?.youtube_id) setFormError(false)
    }, [youtubeUser])

    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [category, setCategory] = useState(null)
    const [privacy, setPrivacy] = useState("public")
    const [sharingError, setSharingError] = useState(null)
    const [textAreaRows, setTextAreaRows] = useState(1)
    const [formError, setFormError] = useState(null)

    const privacyOptions = ["private", "unlisted", "public"]
    const categoryOptions = ["sports", "entertainments", "educations", "trailers"]

    const openGoogleLogin = () => {
        const leaguePath = window.location.pathname.split("/")[1]
        const youtubeLoginUrl = Config.api + leaguePath + "/socials/credentials/youtube/authorize?access_token=" + token
        window.open(youtubeLoginUrl, "_blank", "width=750, height=750")
        setFetchingCredentials(true)
    }

    const resetYoutubeShare = () => {
        setYoutubeUser(null)
        setTitle("")
        setDescription("")
        setCategory(null)
        setPrivacy("public")
        setLoggingOutGoogle(false)
        setTextAreaRows(1)
        setSharingError(false)
        setFormError(false)
        setFetchingCredentials(false)
    }

    const onShareToYoutube = async () => {

        if (!youtubeUser?.youtube_id) {
            setFormError(true)
            return
        }

        const shareToYoutubeBody = {
            media_type: "VIDEO",
            video_url: video.download_link,
            title: title,
            description: description,
            privacyStatus: privacy,
        }

        if (category) shareToYoutubeBody.categoryId = categoryToId[category]

        console.log("Youtube", shareToYoutubeBody);

        const {error} = Backend.post("/socials/share/youtube/" + youtubeUser?.youtube_id, JSON.stringify(shareToYoutubeBody))
        if (error) {
            setSharingError(error)
            console.error("Failed to post", error)
        } else {
            onClose()
            openShareTable()
            console.log("Sharing to Youtube")
        } 
    }

    const onLogoutGoogle = () => {
        const {error} = Backend.delete("/socials/credentials/youtube/" + youtubeUser?.youtube_id)
        if (error) {
            setSharingError(error)
            console.error("Failed to logout", error)
        } else {
            resetYoutubeShare()
            console.log("logged out Google");
        } 
    }

    const onCopyOriginalDescription = () => {
        setTextAreaRows(2)
        setDescription(video.description)
    }

    const onGenerateDescription = () => {
        setDescription("Generating description. Please wait...")
        const generateTextBody = {
            original_title: video.description,
            user_description: "",
            platform: video.output_preset || "",
        }

        Backend.post("/socials/generate_post_description", JSON.stringify(generateTextBody))
            .then(({data, error}) => {
                if (error) {
                    console.error("Failed to generate", error);
                } else {
                    setTextAreaRows(5)
                    setDescription(data.description)
                }
            });
    }

    const youtubeUserDetails = (
        <div className="user-logged-in">
            <div className="logged-in-title">Logged in as</div>
            {youtubeUser ? (
                <div className="share-user-profile">
                    <div className="user-profile-and-name">
                        <img src={youtubeUser.youtube_picture_url} alt="profile img" />
                        <div>{youtubeUser.youtube_name}</div>
                        <button onClick={() => setLoggingOutGoogle(true)} className="small-btn red-btn">Logout</button>
                    </div>
                    <div className={classNames("confirm-logout", {"show": loggingOutGoogle})}>
                        <div className="create-delete-credentials-title">Confirm logout?</div>
                        <div className="delete-credentials-btn">
                            <button 
                                onClick={onLogoutGoogle}
                                className="red-btn small-btn">
                                Logout
                            </button>
                            <button 
                                onClick={() => setLoggingOutGoogle(false)} 
                                className="small-btn">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="no-credentials-found">
                        Login with account that has Youtube channel
                    </div>
                    <div className="login-or-create-credentials">
                        <button onClick={openGoogleLogin} className="login-with-google-btn">
                            <FaGoogle/>
                            Login with Google
                        </button>
                    </div>
                </>
            )}
            {formError && showErrorMessage("Please login to share", !youtubeUser)}
            {sharingError && (
                <div className="share-login-error">{sharingError}</div>
            )}
        </div>
    )

    const shareToYoutubeForm = (
        <div className="share-video-form">
            <div className="input-container">
                <label className="input-title">Title</label>
                <input
                    type="text"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                    className="share-video-title-input"
                    />
            </div>
            <div className="input-container">
                <label className="input-title">Description</label>
                <textarea
                    type="text"
                    rows={textAreaRows}
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                    placeholder=""
                    className="share-video-title-input"
                    />
                <div className="share-video-title-btn">
                    <div onClick={onCopyOriginalDescription} className="copy-title-btn">
                        <RiFileCopyLine/>
                        Use original description
                    </div>
                    {/* TODO make an input for submitting a description to be sent to generate description endpoint */}
                    <div onClick={onGenerateDescription} className="copy-title-btn">
                        <MdOutlineSubtitles/>
                        Generate a description
                    </div>
                </div>
            </div>
            <div className="input-container">
                <label htmlFor="" className="input-title">Category</label>
                <SmallDropdown 
                    value={category}
                    options={categoryOptions}
                    onChange={(v) => setCategory(v)}
                    noneOption/>
            </div>
            <div className="input-container">
                <label htmlFor="" className="input-title">Privacy</label>
                <SmallDropdown 
                    value={privacy}
                    options={privacyOptions}
                    onChange={(v) => setPrivacy(v)}/>
            </div>
        </div>
    )

    return (
        <CustomModal isOpen={!!shareVideo} onRequestClose={onClose} className="share-modal">
            <div className="share-video-modal">
                <div className="share-video-title">Share video to Youtube</div>
                <div className="share-video-and-credentials">
                    <div className="share-video-review">
                        <video src={video.download_link} controls></video>
                    </div>
                    <div className="credentials-and-form">
                        {youtubeUserDetails}
                        {shareToYoutubeForm}
                        <div className="confirm-cancel-btn-cont right">
                            <button onClick={onShareToYoutube} disabled={formError} className="share-to-social-media-btn green-btn">Share to Youtube</button>
                            <button onClick={onClose}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </CustomModal>
    )
}

export default ShareToYoutube