import React, {useEffect, useState, useRef} from "react"
import Config from "../../utility/Config";
import Page from "../../components/Page"
import Dropdown from "../../components/Dropdown";
import { useGetChannels } from "../../utility/UseGetChannels";
import { useUpdateSearchParams } from "../../utility/Utilities";
import { useCheckIsAdmin } from "../../utility/UserGroups";
import { graphSiteDictionary } from "../../utility/StatisticGraphs";
import { usePeriodFilter } from "../../utility/UsePeriodFilter";
import { useMediaQuery } from "react-responsive";
import FullscreenFilter from "../../components/FullscreenFilter";
import { IoMdOptions } from "react-icons/io";
import "../../DataInfoUtility.css"
import "./Statistics.css"
import "../../components/css/ListingFilter.css"
import ExportRecord from "../ExportRecord/ExportRecord";
import classNames from "classnames";
import { ClickOutside } from "../../utility/Utilities";
import { capitalizeFirstLetter } from "../../utility/Utilities";
import { IoMdArrowDropdown } from "react-icons/io"

function Statistics () {

    const statisticGraphs = Config.statisticGraphs
    const {from, to, periodFilterOptions} = usePeriodFilter()
    const [searchParams, updateSearchParams, resetAllSearchParamsExcept] = useUpdateSearchParams()

    const mobileDevice = useMediaQuery({maxWidth: 768})

    const [mobileShowFilters, setMobileShowFilters] = useState(false)
    const [isFsAdmin, isAdmin] = useCheckIsAdmin()
    const isShl = Config.association === "SHL"

    const checkStatisticPagePermission = () => {
        if (isShl) {
            if (isAdmin) return true
            else return false
        } else {
            const leagueWithStatistics = ["SEF", "NTF", "toppserien", "SFL"]
            return leagueWithStatistics.includes(Config.association)
        }
    }
    const showStatisticPage = checkStatisticPagePermission()
    
    const defaultStatisticType = isShl ? "export_record" : "view_count"
    const statisticTypeParam = searchParams.get("type") || defaultStatisticType
    const exportRecordStatistic = statisticTypeParam === "export_record"

    const [openStatisticTypeFilter, setOpenStatisticTypeFilter] = useState(false)
    const [hideGraphs, setHideGraphs] = useState(!exportRecordStatistic ? true : false)

    const channelsList = useGetChannels() || []
    const channels = channelsList.reduce((agg, c) => {agg[c.id] = c; return agg;}, {})
    const channelsById = Object.keys(channels)
    const channelOptions = channelsById.sort((a,b) => channels[a].name.localeCompare(channels[b].name))
    const defaultChannel = (!isAdmin && channelOptions.length !== 0) ? channelOptions[0] : undefined

    const siteParam = searchParams.get("site")
    const videoContentParam = searchParams.get("content")
    const videoTypeParam = searchParams.get("video_type")
    const statisticChannelParam = searchParams.get("channel") || defaultChannel
    
    useEffect(() => {
        if (!showStatisticPage) return
    }, [])

    useEffect(() => {
        if (statisticTypeParam === "other") resetAllSearchParamsExcept(["type", "from", "to"])
    }, [statisticTypeParam])

    useEffect(() => {
        if (exportRecordStatistic) return
        setHideGraphs(true)
    }, [from, to])

    useEffect(() => {
        const clearRefreshStream = setInterval(()=> {
            if (hideGraphs) setHideGraphs(false)
        }, 800)
        return () => clearInterval(clearRefreshStream);
    }, [hideGraphs])

    const statisticTypeDropdownRef = useRef(null)

    ClickOutside(statisticTypeDropdownRef, setOpenStatisticTypeFilter)

    if (!isShl && !statisticGraphs) return null
    if (!from && !to) return null
    if (!showStatisticPage) return null

    const onChangeStatisticType = (type) => {
        console.log(type);
        console.log(statisticTypeParam);
        if (statisticTypeParam === type) return
        setHideGraphs(true)
        updateSearchParams({"type": type})
    }

    const onChangeSite = (site) => {
        if (siteParam === site) return
        if (!site) updateSearchParams("site")
        else updateSearchParams({"site": site})
        setHideGraphs(true)
    }

    const onChangeContent = (content) => {
        if (videoContentParam === content) return
        if (!content) updateSearchParams("content")
        else updateSearchParams({"content": content})
        setHideGraphs(true)
    }

    const onChangeVideoType = (type) => {
        if (videoTypeParam === type) return
        if (!type) updateSearchParams("video_type")
        else updateSearchParams({"video_type": type})
        setHideGraphs(true)
    }

    const onChangeStatisticChannel = (channel) => {
        if (statisticChannelParam === channel) return
        setHideGraphs(true)
        updateSearchParams({"channel": channel})
    }

    const statisticTypeOptions = () => {
        const allStatistics = isShl ? ["export_record"] : ["view_count", "duration", "other", "export_record"]
        const adminStatistics = isShl ? ["export_record"] : ["view_count", "duration", "other"]
        const nonAdminStatistics = ["view_count", "duration"]

        if (isFsAdmin) return allStatistics
        else if (isAdmin) return adminStatistics
        else if (!isAdmin) return nonAdminStatistics
    }

    const siteOptions = statisticGraphs ? statisticGraphs.site : null
    const videoContentOptions = ["official_events", "club_content"]
    const videoTypeOptions = ["live", "VoD"]
    const showOtherFilters = statisticTypeParam !== "other" && !exportRecordStatistic

    const statisticTypeFilter = (
        <div
            // ref={statisticTypeDropdownRef}
            onClick={() => setOpenStatisticTypeFilter(!openStatisticTypeFilter)}  
            className={classNames("filter-dropdown", {"open": openStatisticTypeFilter})}
            >
            <div className="filter-title">Statistic type</div>
            <div className="filter-selected">
                {statisticTypeParam && capitalizeFirstLetter(statisticTypeParam).replace("_", " ")}
                <span className="dropdown-arrow"><IoMdArrowDropdown /></span>
            </div>
            <ul className="filter-dropdown-list">
                {statisticTypeOptions().map((t) => {
                    return (
                        <li 
                            key={t}
                            onClick={() => onChangeStatisticType(t)} 
                            className={classNames("dropdown-item", {"active": statisticTypeParam === t})}>
                            {capitalizeFirstLetter(t).replace("_", " ")}
                        </li>
                    )
                })}
            </ul>
        </div>
    )

    // const statisticTypeFilter = siteOptions && (
    //     <Dropdown
    //         title="Statisic type"
    //         fallback={statisticTypeParam}
    //         selected={statisticTypeParam}
    //         options={statisticTypeOptions()}
    //         onChange={v => onChangeStatisticType(v)}/>
    // )

    const siteFilter = siteOptions && (
        <Dropdown
            title="Site"
            fallback={"All"}
            special={"All"}
            selected={siteParam}
            options={siteOptions}
            onChange={v => onChangeSite(v)}/>
    )

    const videoContentFilter = (
        <Dropdown
            title="Content"
            fallback={"All"}
            special={"All"}
            selected={videoContentParam}
            options={videoContentOptions}
            onChange={v => onChangeContent(v)}/>
    )

    const videoTypeFilter = (
        <Dropdown
            title="Video type"
            fallback={"All"}
            special={"All"}
            selected={videoTypeParam}
            options={videoTypeOptions}
            onChange={v => onChangeVideoType(v)}/>
    )

    const channelFilter = (
        <Dropdown
            title="Channel"
            centered
            special={isAdmin && "All"}
            fallback={isAdmin && "All"}
            selected={statisticChannelParam}
            options={channelOptions}
            render={(id) => channels[id]?.name || ""}
            btnRender={(id) => channels[id]?.name || ""}
            onChange={v => onChangeStatisticChannel(v)}/>
    )

    const statisticFilters = mobileDevice ? (
        <div className="statistic-filters-cont mobile">
            <div onClick={() => setMobileShowFilters(true)}><IoMdOptions/> Filters</div>
        </div>
    ) : (
        <div className="statistic-filters-cont">
            {periodFilterOptions}
            {statisticTypeFilter}
            {showOtherFilters && (
                <>
                    {siteFilter}
                    {videoContentFilter}
                    {videoTypeFilter}
                    {channelFilter}
                </>
            )}
        </div>
    )

    const statisticFiltersMobile = (
        <FullscreenFilter showFilter={mobileShowFilters} onSetFilter={setMobileShowFilters}>
            <>
                {periodFilterOptions}
                {statisticTypeFilter}
                {statisticTypeParam !== "other" && (
                    <>
                        {siteFilter}
                        {videoContentFilter}
                        {videoTypeFilter}
                        {channelFilter}
                    </>
                )}
            </>
        </FullscreenFilter>
    )

    const getStatisticUrl = (type, channel=false) => {

        // doesn't fetch any statistics if it's export record statistic
        if (exportRecordStatistic) return

        const url = statisticGraphs[type].url
        const orgId = `?orgId=${statisticGraphs[type].orgId}`
        const panelId = `&panelId=${statisticGraphs[type].panelId}`
        const fromDate = `&from=${from}`
        const toDate = `&to=${to}`

        const siteNumber = graphSiteDictionary[siteParam] || null
        const site = `&var-http_origin=${siteNumber || "All"}`

        let statisticUrl = url + orgId + panelId + fromDate + toDate + site

        if (channel && statisticChannelParam) {
            statisticUrl += `&var-channel_id=${statisticChannelParam}`
        }
        
        if (videoContentParam) {
            if (videoContentParam === "official_events") statisticUrl += "&var-is_event=t"
            if (videoContentParam === "club_content") statisticUrl += "&var-is_event=f"
        }

        if (videoTypeParam) {
            if (videoTypeParam === "live") statisticUrl += "&var-is_live=t"
            if (videoTypeParam === "VoD") statisticUrl += "&var-is_live=f"
        }
        
        return statisticUrl
    }
    
    const viewCountNumberUrl = getStatisticUrl("viewCountNumber")
    const viewCountTableUrl = getStatisticUrl("viewCountTable")
    const viewCountGraphUrl = getStatisticUrl("viewCountGraph")

    const durationNumberUrl = getStatisticUrl("durationNumber")
    const durationTableUrl = getStatisticUrl("durationTable")
    const durationGraphUrl = getStatisticUrl("durationGraph")
    
    const channelViewNumberUrl = getStatisticUrl("channelViewNumber", true)
    const channelViewGraphUrl = getStatisticUrl("channelViewGraph", true)
    const channelViewTableUrl = getStatisticUrl("channelViewTable", true)

    const channelDurationNumberUrl = getStatisticUrl("channelDurationNumber", true)
    const channelDurationGraphUrl = getStatisticUrl("channelDurationGraph", true)
    const channelDurationTableUrl = getStatisticUrl("channelDurationTable", true)

    const deviceUsageGraphUrl = getStatisticUrl("deviceUsageGraph")

    const globalStatistic = isAdmin && !statisticChannelParam

    const globalViewCountStatistics = (
        <div className="statistic-single-cont">
            <div className="statistic-flex smaller-left">
                <div className="statistic-number">
                    <iframe src={viewCountNumberUrl} title="duration table" frameborder="0"></iframe>
                </div>
                <div className="statistic-table">
                    <iframe src={viewCountTableUrl} title="view count table" frameborder="0"></iframe>
                </div>
            </div>
            <div className="statistic-graph">
                <iframe src={viewCountGraphUrl}  title="view count" frameborder="0"></iframe>
            </div>
        </div>
    )

    const globalDurationStatistics = (
        <div className="statistic-single-cont">
            <div className="statistic-flex">
                <div className="statistic-number">
                    <iframe src={durationNumberUrl} title="duration table" frameborder="0"></iframe>
                </div>
                {globalStatistic && (
                    <div className="statistic-table">
                        <iframe src={durationTableUrl} title="duration table" frameborder="0"></iframe>
                    </div>
                )}
            </div>
            <div className="statistic-graph">
                <iframe src={durationGraphUrl} title="duration" frameborder="0"></iframe>
            </div>
        </div>
    )

    const channelViewCountStatistic = (
        <div className="statistic-single-cont">
            <div className="statistic-flex">
                <div className="statistic-number">
                    <iframe src={channelViewNumberUrl} title="duration table" frameborder="0"></iframe>
                </div>
                <div className="statistic-table">
                    <iframe src={channelViewTableUrl} title="view count table" frameborder="0"></iframe>
                </div>
            </div>
            <div className="statistic-graph">
                <iframe src={channelViewGraphUrl}  title="view count" frameborder="0"></iframe>
            </div>
        </div>
    )

    const channelDurationStatistic = (
        <div className="statistic-single-cont">
            <div className="statistic-flex">
                <div className="statistic-number">
                    <iframe src={channelDurationNumberUrl} title="duration table" frameborder="0"></iframe>
                </div>
                <div className="statistic-table">
                    <iframe src={channelDurationTableUrl} title="view count table" frameborder="0"></iframe>
                </div>
            </div>
            <div className="statistic-graph">
                <iframe src={channelDurationGraphUrl} title="duration" frameborder="0"></iframe>
            </div>
        </div>
    )

    const deviceUsageGraph = (
        <div className="statistic-graph-small">
            <iframe src={deviceUsageGraphUrl} title="duration" frameborder="0"></iframe>
        </div>
    )

    const viewCountStatistics = globalStatistic ? globalViewCountStatistics : channelViewCountStatistic
    const durationStatistics = globalStatistic ? globalDurationStatistics : channelDurationStatistic
    
    return (
        <Page title="Statistics">
            <div className="statistic-cont">
                {statisticFilters}
                {statisticFiltersMobile}
                {statisticTypeParam === "view_count" && viewCountStatistics}
                {statisticTypeParam === "duration" && durationStatistics}
                {statisticTypeParam === "other" && deviceUsageGraph}
                {statisticTypeParam === "export_record" && <ExportRecord/>}
                {hideGraphs && <div className="blank-page-bg"></div>}
            </div>
        </Page>
    )
}

export default Statistics