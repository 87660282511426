import React, {useState, useEffect} from "react"
import { useBackend } from "../../utility/Backend";
import Backend from "../../utility/Backend";
import TaggingForm from "./TaggingForm";
import TaggedEventsList from "./TaggedEventsList";
import { useLoginSession } from "../../stores/loginSession";
import { useFeedbackMessage } from "../../stores/FeedbackMessage";
import { useMutateByRegex } from "../../utility/Utilities";
import { generateEventTitle, tagNameDict } from "./TaggingUtil";
import { TagsOptions, getCompleteTagParams } from "./TaggingUtil";
import { useVideoPlayer } from "../../components/VideoPlayer/VideoPlayerProvider";
import classNames from "classnames";
import "./Tagging.css"

function AssetTagging ({
    taggingState, 
    taggingDispatch, 
    tagsData, 
    playlist=null, 
    isTaggingGame=false,
}) {

    const {token} = useLoginSession()
    const {showFeedback,} = useFeedbackMessage()
    const mutateByRegex = useMutateByRegex()
    let {getCurrentTime} = useVideoPlayer()
    
    const [eventRating, setEventRating] = useState(null)
    const game = playlist.game

    const masterAssetId = playlist.master_videoasset.id

    const {data: assetData} = useBackend("/asset/" + masterAssetId, {access_token: token})

    useEffect(() => {
        taggingDispatch({type: "clear params"})
    }, [taggingState.tag])

    useEffect(() => {
        taggingDispatch({type: "clear players"})
    }, [taggingState.team])

    const {tag, team, player, phase, offending_player, scorer, player_in, player_out, 
        on_target, shot_type, shot_result} = taggingState

    const getEventTitle = () => {
        const tag = tagNameDict[taggingState.tag] || taggingState.tag
        if (!generateEventTitle[tag]) return tag
        else return generateEventTitle[tag](taggingState)
    }
    
    // updating the event title/description whenever there's change in players, event types
    useEffect(() => {
        if (!taggingState.tag) return 
        const descriptionFromTagParams = getEventTitle()
        taggingDispatch({type: "description", payload: descriptionFromTagParams})
    }, [
        tag, team, player, phase, offending_player, scorer, player_in, player_out, 
        on_target, shot_type, shot_result
    ])

    // temporary behind-the-scene event rating
    const getEventRating = () => {
        switch (taggingState.tag) {
            case "goal":
                return 5
            case "penalty":
                return 4
            case "shot":
                if (taggingState.on_target) return 4
                else return 3
            case "red card":
                return 4
            default:
                return null;
        }
    }

    useEffect(() => {
        if (taggingState.tag) setEventRating(getEventRating())
    }, [taggingState.tag, taggingState.on_target])

    const tags = tagsData?.tags || []
    const tagParam = tags[taggingState.tag] || []

    async function onCreateTag () {

        const newEventObject = {
            game_id: game.id,
            tag: getCompleteTagParams(taggingState, tagParam),
            video_asset_offset: taggingState.video_offset,
            playlist: {
                description: taggingState.description,
                is_private: true,
                thumbnail_url: taggingState.thumbnail_url,
                events: [{
                    video_asset_id: playlist.master_videoasset.id,
                    description: taggingState.description,
                    tags: getCompleteTagParams(taggingState, tagParam, true),
                    from_timestamp: taggingState.from_timestamp,
                    to_timestamp: taggingState.to_timestamp,
                }]
            }
        }

        if (eventRating) newEventObject.playlist.rating = eventRating
        // if (isTaggingGame) newEventObject.wall_clock_time = new Date().toISOString()

        // POST request for uploading thumbnail (if there's thumbnail)
        if (taggingState?.thumbnail_url?.imageFile) {
            let body = new FormData();
            body.append("image", taggingState.thumbnail_url.imageFile);

            const {data: uploadedImageData, error: uploadImageError} = await Backend.post(
                "/userimage",
                body,
                {type: "thumbnail", access_token: token},
                {json:false}
            )
            if (uploadImageError) {
                showFeedback("warning", "Failed to upload image: " + uploadImageError)
                return
            } else {
                const uploadedThumbnailUrl = uploadedImageData.path
                console.log("uploaded thumbnail:" + uploadedThumbnailUrl)
                newEventObject.playlist.thumbnail_url = uploadedThumbnailUrl
            }
        }
        
        console.log("creating new event", JSON.stringify(newEventObject, undefined, 2))
        
        Backend.post("/event/", JSON.stringify(newEventObject), {access_token: token})
            .then(({error}) => {
                if (error) {
                    console.error("Failed to POST", error);
                    showFeedback("warning", "Failed to create" + error);
                } else {
                    mutateByRegex(/^\/(event)|(game\/\d+\/events)/)
                    taggingDispatch({type: "reset"})
                    showFeedback("success", "Event is created successfully")
                }
            });
    }
    
    // timestamp when tagging an event
    const assetTaggingTimestamp = getCurrentTime().toFixed(3) * 1000
    // timestamp when tagging a game
    const assetStartTime = new Date(assetData?.start_timestamp).getTime()
    const now = new Date().getTime()
    const gameTaggingTimestamp = now - assetStartTime

    const taggingTimestamp = isTaggingGame ? gameTaggingTimestamp : assetTaggingTimestamp

    return (
        <div className={classNames("tagging-cont", {
            "open-form": taggingState.tag,
            "game-tagging": isTaggingGame
        })}>
            <TagsOptions
                game={game}
                taggingState={taggingState}
                taggingDispatch={taggingDispatch}
                playlist={playlist}
                taggingTimestamp={taggingTimestamp}
                />   
            <TaggingForm 
                taggingState={taggingState} 
                taggingDispatch={taggingDispatch}
                playlist={playlist}
                game={game}
                tagsData={tagsData}
                onCreateTag={onCreateTag}
                />
            {!isTaggingGame && <TaggedEventsList/>}
        </div>
    )
}

export default AssetTagging