import React from "react";
import Config from "./Config";
import useSWR, {SWRConfig} from "swr"
import {objToQuerystring} from "./Utilities";

export function generateHref (path, league=undefined) {
    if (path.startsWith("http")) return path;
    return Config.getBackend(league) + path;
}

const Backend = {
    fetch: async (method, path, query, body, {json=true, headers=undefined}) => {
        let url = new URL(generateHref(path));
        if (typeof query === "object") {
            Object.keys(query).forEach(key => url.searchParams.append(key, query[key]));
        }
        if (!headers) headers = {};

        // ?? [Does it make sense]Skip setting 'Content-Type' to 'application/json' if 'body' is FormData
        if (!(body instanceof FormData) && json && headers["Content-Type"] === undefined) {
            headers["Content-Type"] = "application/json; charset=utf-8";
        } else if (body instanceof FormData) {
            // Ensure no Content-Type header is set for FormData to let the browser set it
            delete headers["Content-Type"];
        }

        const params = {method: method, body: body, mode: "cors", credentials: "include", headers: headers};
        let response = null;
        try {
            response = await fetch(url.href, params);
            let content = null;
            const contentType = response.headers.get("Content-Type");
            
            try {
                if (contentType && contentType.includes("application/json")) {
                    content = await response.json();
                } else {
                    console.warn("Expected JSON response but received:", contentType);
                    content = await response.text();
                }
            } catch (error) {
                console.error("Failed to parse response content", error);
                // If parsing fails, the client isn't going to be expecting any content
            }
            

            if (response.ok) return {data: content, error: null, response: response};

            const error = content?.message || "An error occurred. Status_code=" + response.status
            console.error("Failed request:", url, error, response.status);

            return {data: null, error, response};
        } catch (e) {
            return {data: null, error: "Failed to fetch, " + e, response: response};
        }
    },
    get: async (path, query=undefined, options=undefined) => {
        return Backend.fetch("GET", path, query, undefined, options || {});
    },
    put: async (path, body, query=undefined, options=undefined) => {
        return Backend.fetch("PUT", path, query, body, options || {});
    },
    post: async (path, body, query=undefined, options=undefined) => {
        return Backend.fetch("POST", path, query, body, options || {});
    },
    delete: async (path, query=undefined, options=undefined) => {
        return Backend.fetch("DELETE", path, query, undefined, options || {});
    },
}

export async function fetcher (path) {
    const url = generateHref(path);
    const res = await fetch(url, {mode: "cors", credentials: "include"});

    if (!res.ok) {
        const error = new Error("An error occurred while fetching the data.");
        error.info = await res.json();
        error.status = res.status;
        console.error("Failed request:", url, error.info, error.status);
        throw error;
    }

    return res.json();
}

export function getFullPath (path, query=undefined) {
    /*
    Combine the url path with a query object, by appending the query parameters
     */
    let key = path;
    if (typeof query === "object" && Object.keys(query).length > 0) {
        const prefix = path.includes("?") ? "&" : "?";
        key += objToQuerystring(query, prefix);
    }
    return key;
}

export const infrequentCacheConf = {
    dedupingInterval: 60 * 60 * 1000,
    focusThrottleInterval: 60 * 60 * 1000,
}

export function useBackend (path, query=undefined, swrOptions=undefined) {
    /*
    Small wrapper around useSWR that correctly converts the query parameter
    SWR provides caching of requests and convenient behind-the-scenes data fetching
    Conditional fetching is achieved by sending path=null
    */
    const url = path === null ? null : getFullPath(path, query);
    return useSWR(url, fetcher, swrOptions);
}

export function SWRProvider ({children}) {
    // See https://swr.vercel.app/docs/options#options
    const config = {
        fetcher: fetcher,
        dedupingInterval: 30 * 1000,
        focusThrottleInterval: 60 * 1000,
        errorRetryInterval: 3 * 1000,
        errorRetryCount: 2,
    }
    return (
        <SWRConfig value={config}>
            {children}
        </SWRConfig>
    )
}

export default Backend;
